import React, { createContext, useState } from 'react'

export const MapContext = createContext<{
  setMap: (map: google.maps.Map | null) => void
  map: google.maps.Map | null
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMap: () => {},
  map: null,
})

export const MapProvider = (props: { children: React.ReactNode }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null)

  return <MapContext.Provider value={{ setMap, map }}>{props.children}</MapContext.Provider>
}
