import { Button, useMantineTheme } from '@mantine/core'
import type { FC } from 'react'
import type { MantineButtonProps } from '../../Button'

export const LinkNegative: FC<MantineButtonProps> = (props) => {
  const theme = useMantineTheme()

  return (
    <Button
      size="md"
      compact={true}
      css={{
        'backgroundColor': 'transparent',
        'border': 'none',
        'color': theme.colors.red[0],
        'textDecoration': 'underline',
        '&:not([disabled],[data-loading="true"]):hover': {
          textDecoration: 'none',
          backgroundColor: 'transparent',
        },
        '&[disabled],&[data-loading="true"],&[data-loading="true"]::before': {
          'color': theme.colors.grey[2],
          '&:hover': { textDecoration: 'underline' },
          'backgroundColor': 'transparent',
        },
        '&[disabled],&[data-loading="true"]': {
          svg: {
            fill: theme.colors.grey[3],
          },
        },
      }}
      {...props}
    />
  )
}
