import { ActionIcon } from '@mantine/core'
import { IconClose } from 'App/components/icons/IconClose/IconClose'
import { useLayoutEffect } from 'react'
import { colors } from 'settings/colors'
import { globalStore } from 'stores/globalStore'

export const PanelDetail = (props: {
  defaultPanelTitle: React.ReactNode
  panelTitle: React.ReactNode
  setPanelTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>
  content: React.ReactNode
}) => {
  useLayoutEffect(() => {
    props.setPanelTitle(props.defaultPanelTitle)
  }, [])

  const padding = 30

  return (
    <>
      <div
        css={{
          position: 'sticky',
          top: 0,
          left: 0,
          zIndex: 1, // without this the wind direction icons in weather card appear above this div
          backgroundColor: colors.white,
          paddingTop: padding,
          paddingRight: padding,
          paddingBottom: 2,
          paddingLeft: padding,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <div css={{ fontSize: 24 }}>{props.panelTitle}</div>
        <div css={{ position: 'relative', top: -20, right: -20 }}>
          <ActionIcon
            onClick={() =>
              globalStore.setState((s) => ({ ...s, detailPanel: { ...s.detailPanel, isOpen: false } }))
            }
            variant="transparent"
            css={{ color: colors.midnight }}
          >
            <IconClose />
          </ActionIcon>
        </div>
      </div>
      <div
        css={{
          paddingTop: 0,
          paddingRight: 15,
          paddingBottom: padding,
          paddingLeft: 15,
        }}
      >
        {props.content}
      </div>
    </>
  )
}
