import { MapContext } from 'App/components/MapProvider/MapProvider'
import { useContext, useEffect, useState } from 'react'
import { globalStore } from 'stores/globalStore'
import { makeReactComponentOverlayView } from 'utils/makeReactComponentOverlayView'
import { lngLatStringToLngLatObject } from '../lngLatStringToLngLatObject'
import { CurrentLocation } from './components/CurrentLocation/CurrentLocation'

export const useGuessedLocationMarker = () => {
  const mapContext = useContext(MapContext)
  const [mapOverlay, setMapOverlay] = useState<ReturnType<typeof makeReactComponentOverlayView> | null>(null)
  const lngLat = globalStore.useSelector((s) => s.startupData?.guessedLocation.lngLat)

  useEffect(() => {
    if (mapOverlay) mapOverlay.onRemove()

    if (lngLat && mapContext.map) {
      const newOverlay = makeReactComponentOverlayView({
        maps: window.google.maps,
        map: mapContext.map,
        component: CurrentLocation,
        latLng: new window.google.maps.LatLng(lngLatStringToLngLatObject(lngLat)),
      })

      setMapOverlay(newOverlay)

      newOverlay.render({})
    }
  }, [lngLat, mapContext.map])
}
