import { PanelDetail } from 'App/components/Map/components/Panels/components/PanelDetails/components/PanelDetail/PanelDetail'
import type { TContainerProps } from 'App/components/Map/components/Panels/utils/types'
import { usePanelPositions } from 'App/components/Map/components/Panels/utils/usePanelPositions'
import { PestCard } from 'App/components/PestCard/PestCard'
import { ProductLookup } from 'App/components/ProductLookup/ProductLookup'
import { WeatherCard } from 'App/components/WeatherCard/WeatherCard'
import { translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { globalStore } from 'stores/globalStore'

export const PanelDetails = (props: TContainerProps) => {
  const panelPositions = usePanelPositions(props)
  const [panelTitle, setPanelTitle] = useState<React.ReactNode>(null)

  const { detailPanel, selectedRegion } = globalStore.useSelector((s) => ({
    detailPanel: s.detailPanel,
    selectedRegion: s.selectedRegion,
  }))

  if (!detailPanel.isOpen) return null

  return (
    <div
      css={{
        position: 'absolute',
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        backgroundColor: colors.white,
        color: colors.midnight,
        right: panelPositions.details.right,
        bottom: panelPositions.details.bottom,
        left: panelPositions.details.left,
        maxHeight: panelPositions.details.maxHeight,
        overflow: 'auto',
        height: '100%',
      }}
    >
      <>
        {detailPanel.type === 'WEATHER' && selectedRegion && (
          <PanelDetail
            content={<WeatherCard setPanelTitle={setPanelTitle} />}
            defaultPanelTitle={translate.phrases.landingPage('Weather')}
            setPanelTitle={setPanelTitle}
            panelTitle={panelTitle}
          />
        )}
        {detailPanel.type === 'LABEL_LOOKUP' && (
          <PanelDetail
            content={
              <ProductLookup panelHeight={panelPositions.details.maxHeight} setPanelTitle={setPanelTitle} />
            }
            defaultPanelTitle={translate.phrases.landingPage('Label Lookup')}
            setPanelTitle={setPanelTitle}
            panelTitle={panelTitle}
          />
        )}
        {detailPanel.type === 'PEST' && selectedRegion && (
          <PanelDetail
            content={<PestCard setPanelTitle={setPanelTitle} />}
            defaultPanelTitle={translate.phrases.landingPage('Pest')}
            panelTitle={panelTitle}
            setPanelTitle={setPanelTitle}
          />
        )}
      </>
    </div>
  )
}
