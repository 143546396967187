import { useDisclosure } from '@mantine/hooks'
import type { REGION_META, REGION_VALUE } from 'App/components/Map/utils/RegionalValuesCache'
import { useRef } from 'react'
import { colors } from 'settings/colors'
import { globalStore } from 'stores/globalStore'
import { useSetMapOverlayZIndex } from '../../utils/useSetMapOverlayZIndex'
import { Label } from './components/Label'

export type TRegionalBadgeProps = {
  meta: REGION_META
  value: REGION_VALUE
}

export const RegionalBadge = (props: TRegionalBadgeProps) => {
  const [isHovered, { close, open }] = useDisclosure(false)
  const ref = useRef<HTMLDivElement>(null)
  const selectedRegionId = globalStore.useSelector((s) => s.selectedRegion?.id)
  const isSelected = selectedRegionId === props.meta.id

  useSetMapOverlayZIndex({
    ref,
    mItem: 'M_Region',
    isSelected,
    isHovered,
  })

  const {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    value: { value, containerCSS },
  } = props

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  /* @ts-ignore */
  if (value === '-' || value === null) return null

  const diameter = 42

  return (
    <div
      css={{
        cursor: 'pointer',
        position: 'relative',
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
        border: `2px solid ${isSelected ? colors.black : 'transaprent'}`,
        padding: '0 3px',
        flexDirection: 'column',
        // maxWidth: 220,
        // width: diameter,
        minWidth: diameter,
        height: diameter,
        ...containerCSS,
      }}
      onClick={() => {
        globalStore.setState((s) => ({
          ...s,
          selectedRegion: { id: props.meta.id, name: props.meta.name },
          detailPanel: {
            isOpen: true,
            type: s.detailPanel.type === 'LABEL_LOOKUP' ? 'WEATHER' : s.detailPanel.type,
          },
        }))
      }}
      onMouseEnter={open}
      onMouseLeave={close}
      ref={ref}
    >
      <Label bottom={diameter + 8} bordered={isSelected} visible={isHovered || isSelected}>
        {props.meta.name}
      </Label>
      <div
        css={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: diameter - 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div css={{ width: '100%' }}>{value}</div>
      </div>
    </div>
  )
}
