import { isNil } from '@semios/app-platform-common'
import { colors } from 'settings/colors'
import { globalStore } from 'stores/globalStore'

export const getHeatmapCSS = ({
  value,
  heatmapColoring,
  heatmapExtremes,
}: {
  value: number | null
  heatmapColoring?: ReturnType<typeof globalStore['getState']>['heatmapColoring']
  heatmapExtremes?: ReturnType<typeof globalStore['getState']>['heatmapExtremes']
}): {
  backgroundColor:
    | string
    | ReturnType<typeof globalStore['getState']>['heatmapColoring'][0]
    | typeof colors['midnight']
  color: typeof colors['midnight'] | 'white'
} => {
  if (isNil(value)) return { backgroundColor: colors.midnight, color: 'white' }

  if (isNil(heatmapColoring) || isNil(heatmapExtremes)) {
    const fromGetState = globalStore.getState()

    if (isNil(heatmapColoring)) heatmapColoring = fromGetState.heatmapColoring

    if (isNil(heatmapExtremes)) heatmapExtremes = fromGetState.heatmapExtremes
  }

  const { max, min } = heatmapExtremes

  if (heatmapColoring.length === 1) return { backgroundColor: heatmapColoring[0], color: colors.midnight }

  if (value <= min) return { backgroundColor: heatmapColoring[0], color: colors.midnight }

  if (value >= max)
    return { backgroundColor: heatmapColoring[heatmapColoring.length - 1], color: colors.midnight }

  const percentageBetweenMinAndMax = (100 / (max - min)) * (value - min)
  const percentilesPerArrayItem = 100 / heatmapColoring.length

  return {
    backgroundColor: heatmapColoring[Math.floor(percentageBetweenMinAndMax / percentilesPerArrayItem)],
    color: colors.midnight,
  }
}
