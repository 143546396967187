import { i18n } from 'i18n/i18n'
import type { TPhrases } from '@semios/app-platform-i18n-core'

let phrases: ReturnType<typeof i18n.getDataByLanguage>

export const getTranslatableTemplateForValueTypeGroup = (
  valueTypeGroup: string,
): TPhrases['sidekick'] | null => {
  if (!phrases) {
    phrases = i18n.getDataByLanguage('en')
  }

  const sidekickPhrases = phrases?.sidekick ?? {}
  const foundKey = Object.keys(sidekickPhrases).find((key) => key.startsWith(`${valueTypeGroup}::`))

  if (foundKey) {
    return foundKey as TPhrases['sidekick']
  }

  return null
}
