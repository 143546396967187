import { Menu } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { IconCheck } from 'App/components/icons/IconCheck/IconCheck'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const CountrySelector = ({
  allTheWorld,
  defaultProductCountryCode,
  setAllTheWorld,
}: {
  allTheWorld: boolean
  defaultProductCountryCode: SharedTypes.LandingPage.TProductCountry
  setAllTheWorld: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <span css={{ marginLeft: 4 }}>
      <Menu shadow="md">
        <Menu.Target>
          <span css={{ cursor: 'pointer', color: colors.primary, fontWeight: 'bold' }}>
            {(() => {
              if (allTheWorld) return translate.phrases.placeholder('all regions')

              if (defaultProductCountryCode === 'AU') return translate.phrases.placeholder('Australia')

              return translate.phrases.placeholder('United States')
            })()}
          </span>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item key={String(false)} onClick={() => setAllTheWorld(false)}>
            <div
              css={{ fontWeight: allTheWorld ? 'inherit' : 'bold', display: 'flex', alignItems: 'center' }}
            >
              <div css={{ color: allTheWorld ? 'transparent' : 'inherit', marginRight: 6 }}>
                <IconCheck />
              </div>
              <div>
                {defaultProductCountryCode === 'AU'
                  ? translate.phrases.placeholder('Australia')
                  : translate.phrases.placeholder('United States')}
              </div>
            </div>
          </Menu.Item>
          <Menu.Item key={String(true)} onClick={() => setAllTheWorld(true)}>
            <div
              css={{ fontWeight: !allTheWorld ? 'inherit' : 'bold', display: 'flex', alignItems: 'center' }}
            >
              <div css={{ color: !allTheWorld ? 'transparent' : 'inherit', marginRight: 6 }}>
                <IconCheck />
              </div>
              <div>{translate.phrases.placeholder('All regions')}</div>
            </div>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </span>
  )
}
