import { UnstyledButton } from '@mantine/core'
import { colors } from 'settings/colors'
import { useIsDarkMode } from 'utils/useIsDarkMode'

export const MapButton = (props: {
  icon: React.ReactNode
  isActive: boolean
  label: React.ReactNode
  onClick: () => void
}) => {
  const isDarkMode = useIsDarkMode()

  return (
    <UnstyledButton onClick={props.onClick}>
      <div css={{ color: colors.white, display: 'flex', position: 'relative' }}>
        <div
          css={{
            backgroundColor: props.isActive ? colors.primary : colors.black,
            borderRadius: '50%',
            width: 45,
            height: 45,
            position: 'absolute',
            top: -5,
            left: -10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            border: `2px solid ${colors.grey500}`,
          }}
        >
          {props.icon}
        </div>
        <div
          css={{
            backgroundColor: props.isActive ? colors.primary : colors.black,
            borderRadius: '0 30px 30px 0',
            padding: '6px 12px 6px 40px',
            textTransform: 'uppercase',
            border: isDarkMode ? `2px solid ${colors.grey500}` : undefined,
          }}
        >
          {props.label}
        </div>
      </div>
    </UnstyledButton>
  )
}
