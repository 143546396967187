import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'

export const IconBackChevron = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 10 18')}>
      <path
        d="M8.75 16.5L1.25 9L8.75 1.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
