import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'

export const IconLabel = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 21 21')}>
      <path
        d="M0 3C0 1.34314 1.34314 0 3 0H13.5C15.1569 0 16.5 1.34314 16.5 3V13.5H21V16.5C21 18.9853 18.9853 21 16.5 21H4.5C2.01472 21 0 18.9853 0 16.5V3ZM16.5 15V19.5C18.1569 19.5 19.5 18.1569 19.5 16.5V15H16.5ZM15 19.5V3C15 2.17158 14.3285 1.5 13.5 1.5H3C2.17158 1.5 1.5 2.17158 1.5 3V16.5C1.5 18.1569 2.84314 19.5 4.5 19.5H15ZM4.5 5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H11.25C11.6642 4.5 12 4.83579 12 5.25C12 5.66421 11.6642 6 11.25 6H5.25C4.83579 6 4.5 5.66421 4.5 5.25ZM4.5 9.75C4.5 9.33579 4.83579 9 5.25 9H11.25C11.6642 9 12 9.33579 12 9.75C12 10.1642 11.6642 10.5 11.25 10.5H5.25C4.83579 10.5 4.5 10.1642 4.5 9.75ZM4.5 14.25C4.5 13.8358 4.83579 13.5 5.25 13.5H8.25C8.66421 13.5 9 13.8358 9 14.25C9 14.6642 8.66421 15 8.25 15H5.25C4.83579 15 4.5 14.6642 4.5 14.25Z"
        fill="white"
      />
    </svg>
  )
}
