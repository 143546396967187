import { App } from 'App/App'
import { initI18n } from 'i18n/i18n'
import ReactDOM from 'react-dom/client'
import type { TSettings } from 'settings/settings'
import { loadSettings } from 'settings/settings'
import { startUp } from 'utils/landingMap/utils/startUp'

export const landingMap = async (o: {
  containerForMap: HTMLElement
  mapHeight: string | number
  mapWidth: string | number
  googleMapsApiKey: TSettings['googleMapsApiKey']
  hubApiUrl: TSettings['hubApiUrl']
  hubAppUrl: TSettings['hubAppUrl']
  forceEnglish: boolean
}) => {
  loadSettings({
    googleMapsApiKey: o.googleMapsApiKey,
    hubApiUrl: o.hubApiUrl,
    hubAppUrl: o.hubAppUrl,
  })

  await initI18n({ forceEnglish: o.forceEnglish })

  await startUp()

  const rootMap = ReactDOM.createRoot(o.containerForMap)

  rootMap.render(<App mapHeight={o.mapHeight} mapWidth={o.mapWidth} />)

  return {
    cleanup: () => {
      rootMap.unmount()
    },
  }
}
