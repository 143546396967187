import type { FC } from 'react'
import { Button, useMantineTheme } from '@mantine/core'
import type { MantineButtonProps } from '../../Button'

export const Tertiary: FC<MantineButtonProps> = (props) => {
  const theme = useMantineTheme()

  return (
    <Button
      css={{
        'background': theme.colors.white[0],
        'height': 32,
        'padding': '0 15px',
        'border': `2px solid ${theme.colors.midnight[0]}`,
        'fontStyle': 'normal',
        'fontWeight': 700,
        'fontSize': 14,
        'lineHeight': '19px',
        'color': theme.colors.midnight[0],
        '&:not([disabled],[data-loading="true"]):hover': {
          background: theme.colors.midnight[0],
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          color: theme.colors.white[0],
        },
        '&[disabled],&[data-loading="true"]': {
          background: theme.colors.white[0],
          border: `2px solid ${theme.colors.grey[1]}`,
          color: theme.colors.grey[2],
          boxShadow: 'none',
          svg: {
            fill: theme.colors.grey[3],
          },
        },
        '&[data-loading="true"]::before': {
          background: 'unset',
        },
        // TODO: Blair: Not sure about this. Think about a med / large option for all buttons. Run by UX.
        ...(props.size === 'lg' || props.size === 'xl'
          ? {
              height: 40,
              fontSize: 16,
              lineHeight: '21px',
            }
          : {}),
      }}
      {...props}
    />
  )
}
