import { globalStore } from 'stores/globalStore'
import { apiFetch } from 'utils/apiFetch'

export const startUp = async () => {
  try {
    const { data } = await apiFetch({ url: '/landing-page-startup', body: {} })

    globalStore.setState((s) => ({
      ...s,
      startupData: data,
      selectedRegion: {
        id: data.guessedLocation.closestRegion.i,
        name: data.guessedLocation.closestRegion.n,
      },
    }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
