import { landingMap } from 'utils/landingMap/landingMap'

landingMap({
  containerForMap: document.getElementById('landing-page-app-container') as HTMLElement,
  mapHeight: '100%',
  mapWidth: '100%',
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  hubApiUrl: import.meta.env.VITE_HUB_API_URL,
  hubAppUrl: import.meta.env.VITE_HUB_APP_URL,
  forceEnglish: true,
})
