import { usePanelPositions } from 'App/components/Map/components/Panels/utils/usePanelPositions'
import { lngLatStringToLngLatObject } from 'App/components/Map/utils/lngLatStringToLngLatObject'
import { MapContext } from 'App/components/MapProvider/MapProvider'
import { useContext, useEffect } from 'react'
import { globalStore } from 'stores/globalStore'

/**
 * This hook pans the map to the selected region when the details panel opens...
 *
 * If there is enough space to the left of the details panel, we put the selected region in that
 * available space to the left of the details panel.
 *
 * If there is not enough space to the left of the details panel (Portrait mode on a mobile device),
 * then we put the selected region in the top left... Which is above the details panel and to the left of the
 * map controls... It's not perfect but seems good enough for now!
 */
export const usePanMapToSelectedRegionWhenDetailsPanelOpens = (o: {
  containerHeight: number
  containerWidth: number
}) => {
  const { map } = useContext(MapContext)
  const detailPanelIsOpen = globalStore.useSelector((s) => s.detailPanel.isOpen)
  const panelPositions = usePanelPositions(o)
  const xDetailPanelLeft = panelPositions.details.left
  const xCenter = o.containerWidth / 2
  const yCenter = o.containerHeight / 2

  useEffect(() => {
    if (detailPanelIsOpen) {
      const { startupData, selectedRegion } = globalStore.getState()

      if (startupData && selectedRegion) {
        const region = startupData.allRegions[selectedRegion.id]
        const selectedLngLat = lngLatStringToLngLatObject(region.l)

        if (map) {
          if (xCenter < xDetailPanelLeft) {
            const moveX = xCenter / 2 - (xDetailPanelLeft - xCenter)

            map.panTo(selectedLngLat)

            map.panBy(moveX, 0)
          } else if (xDetailPanelLeft > 80) {
            const xPixelsBehindDetailsPanel = xCenter - xDetailPanelLeft
            const moveX = xPixelsBehindDetailsPanel + xDetailPanelLeft / 2

            map.panTo(selectedLngLat)

            map.panBy(moveX, 0)
          } else {
            const moveX = xCenter - 60
            const moveY = yCenter - 60

            map.panTo(selectedLngLat)

            map.panBy(moveX, moveY)
          }
        }
      }
    }
  }, [detailPanelIsOpen, xCenter, yCenter, xDetailPanelLeft])
}
