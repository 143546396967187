import { useElementSize } from '@mantine/hooks'
import { GoogleMap } from 'App/components/GoogleMap/GoogleMap'
import { Panels } from 'App/components/Map/components/Panels/Panels'
import { lngLatStringToLngLatObject } from 'App/components/Map/utils/lngLatStringToLngLatObject'
import { googleMapsDarkModeStyle } from 'App/components/Map/utils/map-styles/googleMapsDarkModeStyle'
import { googleMapsLightModeStyle } from 'App/components/Map/utils/map-styles/googleMapsLightModeStyle'
import { populateCacheFromStartupData } from 'App/components/Map/utils/populateCacheFromStartupData'
import { updateMapWithAnyNeededCurrentValues } from 'App/components/Map/utils/updateMapWithAnyNeededCurrentValues'
import { usePanMapToSelectedRegionWhenDetailsPanelOpens } from 'App/components/Map/utils/usePanMapToSelectedRegionWhenDetailsPanelOpens'
import { MapContext } from 'App/components/MapProvider/MapProvider'
import { useContext, useMemo } from 'react'
import { colors } from 'settings/colors'
import { customTheme } from 'settings/ui-theme'
import { globalStore } from 'stores/globalStore'
import { useIsDarkMode } from 'utils/useIsDarkMode'
import { useGuessedLocationMarker } from './utils/useGuessedLocationMarker/useGuessedLocationMarker'
import { useRegionBoundary } from './utils/useRegionBoundary/useRegionBoundary'

export type TLngLat = `POINT(${number} ${number})`

export const Map = (props: { mapHeight: string | number; mapWidth: string | number }) => {
  const mapDiv = useElementSize<HTMLDivElement>()
  const isDarkMode = useIsDarkMode()
  const mapContext = useContext(MapContext)

  useGuessedLocationMarker()

  useRegionBoundary()

  usePanMapToSelectedRegionWhenDetailsPanelOpens({
    containerHeight: mapDiv.height,
    containerWidth: mapDiv.width,
  })

  const onInit = (map: google.maps.Map, maps: typeof google.maps) => {
    mapContext.setMap(map)

    const { regionalValuesCache } = populateCacheFromStartupData({ map, maps })

    const refreshMap = async () => {
      // if (globalStore.getState().loadingCurrentValues) return

      globalStore.setState((s) => ({ ...s, loadingCurrentValues: true }))

      await updateMapWithAnyNeededCurrentValues({ regionalValuesCache, map })

      globalStore.setState((s) => ({ ...s, loadingCurrentValues: false }))
    }

    const refreshMapOnIdleListener = map.addListener('idle', () => {
      refreshMap()
    })

    const selectedValueGroupsListener = globalStore.subscribeToChanges((s) => s.primaryValueGroup, refreshMap)

    return () => {
      refreshMapOnIdleListener.remove()

      selectedValueGroupsListener() // unsubscribes
    }
  }

  const mapOptions = useMemo(() => {
    return {
      mapTypeId: 'terrain',
      disableDefaultUI: true,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      minZoom: 3,
      tilt: 0,
      styles: isDarkMode ? googleMapsDarkModeStyle : googleMapsLightModeStyle,
      // TODO: This is a quick hack... Remove this once a better all around "api is down" experience is built. For now fallback to a starting view of the US if the api is down
      ...(() => {
        if (globalStore.getState().startupData) {
          return {
            center: lngLatStringToLngLatObject(
              globalStore.getState().startupData?.guessedLocation.lngLat as TLngLat,
            ),
            zoom: 7,
          }
        } else {
          return {
            center: { lat: 36.974407958175476, lng: -102.15638761658516 },
            zoom: 4,
          }
        }
      })(),
    }
  }, [isDarkMode])

  const fontFamily = customTheme.fontFamily as string

  return (
    <>
      <div
        ref={mapDiv.ref}
        css={{
          'height': props.mapHeight,
          'width': props.mapWidth,
          'position': 'relative',
          'boxSizing': 'border-box',
          '*,*::before,*::after': { boxSizing: 'border-box' },
          fontFamily,
          'color': colors.black,
        }}
      >
        <GoogleMap height={props.mapHeight} mapOptions={mapOptions} onInit={onInit} width={props.mapWidth} />
        <Panels containerHeight={mapDiv.height} containerWidth={mapDiv.width} />
      </div>
    </>
  )
}
