import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { get, isEmpty } from '@semios/app-platform-common'
import FuzzySearch from 'fuzzy-search'
import { translate } from 'i18n/i18n'
import { useEffect, useMemo } from 'react'
import { globalStore } from 'stores/globalStore'
import { apiFetch } from 'utils/apiFetch'

export const useAutocompleteData = ({
  allTheWorld,
  defaultProductCountryCode,
  searchText,
}: {
  allTheWorld: boolean
  defaultProductCountryCode: SharedTypes.LandingPage.TProductCountry
  searchText: string
}) => {
  const allProducts = globalStore.useSelector((s) => s.allProducts)

  useEffect(() => {
    if (allTheWorld && isEmpty(allProducts)) {
      apiFetch({ url: '/landing-page-all-products', body: {} }).then((res) => {
        if (res?.data?.allProducts) {
          globalStore.setState((s) => ({ ...s, allProducts: res.data.allProducts }))
        }
      })
    }
  }, [allTheWorld])

  const autocompleteData = useMemo(() => {
    if (!searchText) return []

    const productDictionaryToUse = allTheWorld
      ? allProducts
      : globalStore.getState().startupData?.guessedLocation.productsForCountry ?? {}

    const productsToSearch = Object.entries(productDictionaryToUse).map(([productId, val]) => {
      const productName = val.n
      // a bit of a hack to silence TypeScript
      const countryCode = allTheWorld ? get(val, 'c') : defaultProductCountryCode

      return {
        value: productId,
        label: allTheWorld
          ? translate.phrases.placeholder('{{productName}} ({{countryCode}})', {
              productName,
              countryCode,
            })
          : productName,
      }
    })

    return new FuzzySearch(productsToSearch, ['label'], {
      caseSensitive: false,
      sort: true,
    }).search(searchText)
  }, [searchText, allProducts, allTheWorld, defaultProductCountryCode])

  return autocompleteData
}
