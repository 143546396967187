import type { FC } from 'react'
import { getButtonComponent } from '../_utils/getButtonComponent'
import { useAsyncButtonProps } from './_utils/useAsyncButtonProps'
import type { ButtonProps } from '../Button'

export const ButtonAsync: FC<ButtonProps> = (props) => {
  const { variant } = props
  const ButtonComponent = getButtonComponent(variant)
  const asyncButtonProps = useAsyncButtonProps(props)

  return <ButtonComponent {...asyncButtonProps} />
}
