import { IconCurrentLocationMarker } from 'App/components/icons/IconCurrentLocationMarker/IconCurrentLocationMarker'
import { useRef } from 'react'
import { useSetMapOverlayZIndex } from '../../../useSetMapOverlayZIndex'

export const CurrentLocation = () => {
  const ref = useRef<HTMLDivElement>(null)

  useSetMapOverlayZIndex({ ref, mItem: 'M_CurrentLocation' })

  return (
    <div css={{ fontSize: 50 }} ref={ref}>
      <IconCurrentLocationMarker />
    </div>
  )
}
