import { MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { Map } from 'App/components/Map/Map'
import { MapProvider } from 'App/components/MapProvider/MapProvider'
import { globalLanguageStore, translate } from 'i18n/i18n'
import React from 'react'
import { customTheme } from 'settings/ui-theme'

export const App = (props: { mapHeight: string | number; mapWidth: string | number }) => {
  const globalLanguage = globalLanguageStore.useSelector((lng) => lng)

  return (
    <React.Fragment key={globalLanguage}>
      <MapProvider>
        <MantineProvider theme={customTheme}>
          <DatesProvider
            settings={{ locale: globalLanguage, firstDayOfWeek: translate.dates.firstDayOfWeek() }}
          >
            <Map mapHeight={props.mapHeight} mapWidth={props.mapWidth} />
          </DatesProvider>
        </MantineProvider>
      </MapProvider>
    </React.Fragment>
  )
}
