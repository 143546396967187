import { PanelButtons } from 'App/components/Map/components/Panels/components/PanelButtons/PanelButtons'
import { PanelDetails } from 'App/components/Map/components/Panels/components/PanelDetails/PanelDetails'
import { PanelMapControls } from 'App/components/Map/components/Panels/components/PanelMapControls/PanelMapControls'
import type { TContainerProps } from 'App/components/Map/components/Panels/utils/types'

export const Panels = (props: TContainerProps) => {
  return (
    <>
      <PanelMapControls {...props} />
      <PanelButtons {...props} />
      <PanelDetails {...props} />
    </>
  )
}
