import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { useCallback, useEffect, useState } from 'react'
import { globalStore } from 'stores/globalStore'
import { ProductSearchCard } from './components/ProductSearchCard/ProductSearchCard'
import { SelectedProductCard } from './components/SelectedProductCard/SelectedProductCard'
import { useAutocompleteData } from './utils/useAutocompleteData'

export const ProductLookup = ({
  panelHeight,
  setPanelTitle,
}: {
  panelHeight: number
  setPanelTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>
}) => {
  const [showIntroductoryText, setShowIntroductoryText] = useState(true)
  const [allTheWorld, setAllTheWorld] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<SharedTypes.LandingPage.TProductId | null>(null)

  const defaultProductCountryCode = globalStore.useSelector(
    (s) => s.startupData?.guessedLocation.productCountryCode as SharedTypes.LandingPage.TProductCountry,
  )

  useEffect(() => {
    if (!selectedProduct) {
      if (showIntroductoryText) {
        setPanelTitle(translate.phrases.landingPage('Label Lookup'))
      } else {
        setPanelTitle(null)
      }
    }
  }, [showIntroductoryText, selectedProduct])

  const autocompleteData = useAutocompleteData({ allTheWorld, defaultProductCountryCode, searchText })

  const resetProductForm = useCallback(() => {
    setSelectedProduct(null)

    setShowIntroductoryText(true)

    setSearchText('')
  }, [])

  return selectedProduct ? (
    <SelectedProductCard
      resetProductForm={resetProductForm}
      selectedProduct={selectedProduct}
      setPanelTitle={setPanelTitle}
    />
  ) : (
    <ProductSearchCard
      allTheWorld={allTheWorld}
      autocompleteData={autocompleteData}
      defaultProductCountryCode={defaultProductCountryCode}
      panelHeight={panelHeight}
      resetProductForm={resetProductForm}
      searchText={searchText}
      setAllTheWorld={setAllTheWorld}
      setSearchText={setSearchText}
      setSelectedProduct={setSelectedProduct}
      setShowIntroductoryText={setShowIntroductoryText}
      showIntroductoryText={showIntroductoryText}
    />
  )
}
