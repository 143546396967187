import { TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Button } from 'App/components/Button/Button'
import { IconChevronCircle } from 'App/components/icons/IconChevronCircle/IconChevronCircle'
import { SuccessMessage } from 'App/components/SignUp/components/SuccessMessage/SuccessMessage'
import { globalLanguageStore, translate } from 'i18n/i18n'
import { useState } from 'react'
import { colors } from 'settings/colors'
import { settings } from 'settings/settings'
import { apiFetch } from 'utils/apiFetch'

export const SignUp = (props: { ctaText: string }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [successEmail, setSuccessEmail] = useState<string | null>(null)

  const form = useForm<{ email: string }>({
    initialValues: { email: '' },
    validate: ({ email }) => {
      if (!email) {
        return {
          email: translate.phrases.placeholder('Please enter your email'),
        }
      }

      const EMAIL_VALIDATION_REGEX_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!EMAIL_VALIDATION_REGEX_PATTERN.test(email))
        return {
          email: translate.phrases.placeholder('Please enter a valid email'),
        }

      return {}
    },
  })

  return (
    <div>
      <form
        onSubmit={form.onSubmit((values) => {
          setSubmitLoading(true)

          form.reset()

          setErrors([])

          setSuccessEmail(null)

          apiFetch({
            url: '/landing-page-self-registration-request',
            body: {
              email: values.email,
              language: globalLanguageStore.getState(),
              hubApp: settings.hubAppUrl,
            },
          })
            .then((res) => {
              if (!!res.errors.length) {
                setErrors(
                  res.errors.map(({ messageTranslationString }) =>
                    translate.phrases.placeholder(messageTranslationString),
                  ),
                )
              } else if (!res.data) {
                setErrors([translate.phrases.placeholder('Sorry… Something went wrong.')])
              } else {
                setSuccessEmail(values.email)
              }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error(error)

              setErrors([translate.phrases.placeholder('Sorry… Something went wrong.')])
            })
            .finally(() => {
              setSubmitLoading(false)
            })
        })}
      >
        {successEmail ? (
          <SuccessMessage email={successEmail} />
        ) : (
          <div css={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <div css={{ fontWeight: 'bold' }}>{props.ctaText}</div>
            <TextInput
              placeholder={translate.phrases.placeholder('Enter your email address')}
              {...form.getInputProps('email')}
            />
            {errors.length ? (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  color: colors.red,
                  fontStyle: 'italic',
                  fontSize: 14,
                }}
              >
                {errors.map((e, i) => (
                  <div key={i}>{e}</div>
                ))}
              </div>
            ) : null}
            <Button variant="primary" type="submit" loading={submitLoading}>
              <div css={{ gap: 12, display: 'flex', alignItems: 'center' }}>
                {translate.phrases.placeholder('Create a Free Account')}
                <span css={{ transform: 'rotate(-90deg)', color: colors.white }}>
                  <IconChevronCircle />
                </span>
              </div>
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}
