import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { unitConverters } from 'utils/unitConverters'
import { getTranslatableTemplateForValueTypeGroup } from './getTranslatableTemplateForValueTypeGroup'

export const getWeatherStatements = {
  air_temperature_sidekick: (
    templatedValue: routes.LandingPageValuesCurrent.Response['regions'][string]['air_temperature_sidekick'],
  ): string | null => {
    if (!templatedValue) return null

    const {
      lastYearDiff,
      lastYearIsWarmer,
      lastYearMean,
      nextWeekMax,
      nextWeekMean,
      nextWeekMin,
      nextWeekThreshold,
      thisWeekMax,
      thisWeekMin,
      thisWeekMean,
      thisWeekThreshold,
    } = templatedValue.value.statements.values

    const templateToUse = getTranslatableTemplateForValueTypeGroup('air_temperature_sidekick')

    if (!templateToUse) return null

    const roundingOptions = { decimalPlaces: 0 }

    const translatedValue = translate.phrases.sidekick(templateToUse, {
      lastYearDiff: unitConverters.temperature(lastYearDiff, roundingOptions).valueWithSuffix(),
      lastYearIsWarmer: lastYearIsWarmer
        ? translate.phrases.sidekick('warmer')
        : translate.phrases.sidekick('cooler'),
      lastYearMean: unitConverters.temperature(lastYearMean, roundingOptions).valueWithSuffix(),
      nextWeekMax: unitConverters.temperature(nextWeekMax, roundingOptions).valueWithSuffix(),
      nextWeekMin: unitConverters.temperature(nextWeekMin, roundingOptions).valueWithSuffix(),
      nextWeekMean: unitConverters.temperature(nextWeekMean, roundingOptions).valueWithSuffix(),
      nextWeekThreshold: translate.phrases.sidekick(nextWeekThreshold),
      thisWeekMax: unitConverters.temperature(thisWeekMax, roundingOptions).valueWithSuffix(),
      thisWeekMin: unitConverters.temperature(thisWeekMin, roundingOptions).valueWithSuffix(),
      thisWeekMean: unitConverters.temperature(thisWeekMean, roundingOptions).valueWithSuffix(),
      thisWeekThreshold: thisWeekThreshold,
    })

    const templateParts = translatedValue.split('. ')

    let translatedValueWithNullsExcluded = translatedValue

    try {
      const filteredParts: string[] = []

      templateParts.forEach((part) => {
        let hasValues

        if (part.includes('Last week')) {
          hasValues = thisWeekMax !== null && thisWeekMin !== null && thisWeekMean !== null
        } else if (part.includes('Next week')) {
          hasValues = nextWeekMax !== null && nextWeekMin !== null && nextWeekMean !== null
        } else if (part.includes('Last year')) {
          hasValues = lastYearDiff !== null && lastYearMean !== null
        }

        if (hasValues) {
          filteredParts.push(part)
        }

        translatedValueWithNullsExcluded = filteredParts.join('. ')
      })
    } catch {
      translatedValueWithNullsExcluded = translatedValue
    }

    return translatedValueWithNullsExcluded
  },
}
