import { NumberInput } from './NumberInput'
import { globalStore } from 'stores/globalStore'
import type { unitConverters } from 'utils/unitConverters'

const ReadonlyNumberInput = (props: { value: number }) => (
  <NumberInput
    readOnly={true}
    value={props.value}
    css={{ maxWidth: 44 }}
    styles={{ input: { padding: 0, textAlign: 'center', pointerEvents: 'none' } }}
    hideControls
  />
)

export const DefaultHeatmapRow = ({
  suffix: suffixProp,
  unitConverterFunctionForRendering,
}: {
  suffix?: string
  unitConverterFunctionForRendering?: typeof unitConverters[keyof typeof unitConverters]
}) => {
  const heatmapColoring = globalStore.useSelector((s) => s.heatmapColoring)
  const heatmapExtremes = globalStore.useSelector((s) => s.heatmapExtremes)

  if (!heatmapColoring.length) return null

  const suffix = suffixProp ?? unitConverterFunctionForRendering?.()?.suffix()

  const min = unitConverterFunctionForRendering
    ? Number(unitConverterFunctionForRendering(heatmapExtremes.min).value())
    : heatmapExtremes.min

  const max = unitConverterFunctionForRendering
    ? Number(unitConverterFunctionForRendering(heatmapExtremes.max).value())
    : heatmapExtremes.max

  return (
    <div
      css={{
        width: '100%',
        background: 'transparent',
        borderRadius: 3,
        display: 'flex',
      }}
    >
      <div>
        <ReadonlyNumberInput value={min} />
      </div>
      <div
        css={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            marginTop: 4,
            width: '100%',
            display: 'flex',
            // background: colors.grey50,
            background: 'white',
            padding: '8px 6px',
          }}
        >
          {heatmapColoring.map((color, i) => (
            <div css={{ flex: 1, background: color, height: 12 }} key={i} />
          ))}
        </div>
        {!!suffix && <div css={{ fontSize: 12, marginTop: 4 }}>{suffix}</div>}
      </div>
      <div>
        <ReadonlyNumberInput value={max} />
      </div>
    </div>
  )
}
