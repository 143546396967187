import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { init } from 'utils/simple-store/simple-store'
import type { TRGBAColorWith1AtTheEnd } from 'settings/colors'
import { colors } from 'settings/colors'

const generateDefaultHeatmapHighLowColors = () => [
  colors.highLowHeatmapStrongBlue,
  colors.highLowHeatmapMediumBlue,
  colors.highLowHeatmapMediumYellow,
  colors.highLowHeatmapMediumOrange,
  colors.highLowHeatmapStrongRed,
]

const heatmapExtremesMap = {
  temperature_minMedianMax_in_c: { min: 5, max: 30 },
  precipitation_next24HoursSumMinMedianMax_mm: { min: 0, max: 5 },
  precipitation_following7DaysSumMinMedianMax_mm: { min: 0, max: 35 },
  windSpeed_minMedianMax_mps: { min: 0, max: 5 },
}

export const globalStore = init<{
  allProducts: routes.LandingPageAllProducts.Response['data']['allProducts']
  detailPanel: { isOpen: boolean; type: 'WEATHER' | 'LABEL_LOOKUP' | 'PEST' }
  heatmapColoring: TRGBAColorWith1AtTheEnd[]
  heatmapExtremes: { min: number; max: number }
  loadingCurrentValues: boolean
  primaryValueGroup:
    | 'temperature_minMedianMax_in_c'
    | 'precipitation_next24HoursSumMinMedianMax_mm'
    | 'precipitation_following7DaysSumMinMedianMax_mm'
    | 'windSpeed_minMedianMax_mps'
  regionsGeometries: Record<
    keyof NonNullable<routes.LandingPageFieldAssetConfigurations.Response['data']['regions']>,
    NonNullable<
      NonNullable<routes.LandingPageFieldAssetConfigurations.Response['data']['regions']>[string]['geometry']
    >
  >
  selectedRegion?: { id: string; name: string }
  startupData?: routes.LandingPageStartup.Response['data']
}>({
  allProducts: {},
  detailPanel: { isOpen: false, type: 'WEATHER' },
  heatmapColoring: generateDefaultHeatmapHighLowColors(),
  heatmapExtremes: heatmapExtremesMap['temperature_minMedianMax_in_c'],
  loadingCurrentValues: false,
  primaryValueGroup: 'temperature_minMedianMax_in_c',
  regionsGeometries: {},
  selectedRegion: undefined,
  startupData: undefined,
})

// doing this will allow our heatmap to scale dynamically when a new valueType is on the map
globalStore.subscribeToChanges(
  (s) => [s.primaryValueGroup],
  () => {
    globalStore.setState((s) => ({ ...s, heatmapExtremes: heatmapExtremesMap[s.primaryValueGroup] }))
  },
)
