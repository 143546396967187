import { alphaColorReplacer, colors } from 'settings/colors'

export const Label = (props: {
  bordered: boolean
  bottom: number
  children: React.ReactNode
  visible: boolean
}) => {
  return (
    <div css={{ position: 'absolute', bottom: props.bottom }}>
      <div
        css={{
          opacity: props.visible ? 1 : 0,
          whiteSpace: 'nowrap',
          fontSize: 14,
          borderRadius: '10px',
          paddingTop: 4,
          paddingBottom: 4,
          paddingLeft: 8,
          paddingRight: 8,
          backgroundColor: alphaColorReplacer(colors.midnight, 0.8),
          color: 'white',
          border: `1px solid ${alphaColorReplacer(colors.white, props.bordered ? 1 : 0)}`,
          transition: props.visible ? '0.25s ease all' : '0.15s ease all',
        }}
      >
        {props.children}
      </div>
    </div>
  )
}
