import { unitConverters } from 'utils/unitConverters'
import { DefaultHeatmapRow } from './DefaultHeatmapRow'
import { Select } from '@mantine/core'
import { globalStore } from 'stores/globalStore'
import { colors } from 'settings/colors'

const MANTINE_SELECT_RIGHT_ICON_CHANGER = {
  input: { cursor: 'pointer', paddingRight: 27 },
  root: {
    '& [aria-expanded="true"]': {
      '& .mantine-Select-rightSection': {
        transform: 'rotate(180deg)',
      },
    },
  },
  rightSection: {
    transition: '0.3s ease all',
    transform: 'rotate(0deg)',
    marginRight: -3,
    svg: {
      color: `${colors.midnight} !important`,
      transform: 'translateY(-3px)',
      scale: '200%',
      clipPath: 'polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%)',
    },
  },
}

const unitConverterMap = {
  temperature_minMedianMax_in_c: 'temperature',
  precipitation_next24HoursSumMinMedianMax_mm: 'precipitation',
  precipitation_following7DaysSumMinMedianMax_mm: 'precipitation',
  windSpeed_minMedianMax_mps: 'windSpeed',
}

export const MapControls = () => {
  const primaryValueGroup = globalStore.useSelector((s) => s.primaryValueGroup)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const updateMapSettings = (value) => {
    globalStore.setState((s) => ({
      ...s,
      primaryValueGroup: value,
    }))
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const unitConverter = unitConverters[unitConverterMap[primaryValueGroup]]

  return (
    <>
      <Select
        onChange={updateMapSettings}
        data={[
          { value: 'temperature_minMedianMax_in_c', label: 'Temperature' },
          { value: 'precipitation_next24HoursSumMinMedianMax_mm', label: 'Rain Next 24 Hours' },
          { value: 'precipitation_following7DaysSumMinMedianMax_mm', label: 'Rain Next 7 Days' },
          { value: 'windSpeed_minMedianMax_mps', label: 'Wind' },
        ]}
        value={primaryValueGroup}
        styles={{ ...MANTINE_SELECT_RIGHT_ICON_CHANGER }}
        style={{ marginBottom: 5 }}
      />

      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore */}
      <DefaultHeatmapRow unitConverterFunctionForRendering={unitConverter} />
    </>
  )
}
