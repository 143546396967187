import { isNil } from '@semios/app-platform-common'
import type { RegionalValuesCache } from 'App/components/Map/utils/RegionalValuesCache'
import { IconWindDirection } from 'App/components/WeatherCard/icons/IconWindDirection'
import { globalStore } from 'stores/globalStore'
import { apiFetch } from 'utils/apiFetch'
import { getHeatmapCSS } from 'utils/getHeatmapCSS'
import { unitConverters } from 'utils/unitConverters'

export const updateMapWithAnyNeededCurrentValues = async ({
  regionalValuesCache,
  map,
}: {
  regionalValuesCache: RegionalValuesCache
  map: google.maps.Map
}) => {
  const { heatmapColoring, heatmapExtremes, primaryValueGroup, startupData } = globalStore.getState()

  if (!startupData) return

  const regionValues = regionalValuesCache.getItemsToProcess({
    cacheKey: primaryValueGroup,
    shouldShowItem: (() => {
      // zoom: the higher the number, the closer to the earth
      const currentZoom = map.getZoom()
      const currentBounds = regionalValuesCache.map.getBounds()

      if (!currentZoom || !currentBounds) return () => false

      return (o) => {
        const isInBounds = currentBounds.contains(o.meta.latLng)

        return isInBounds
      }
    })(),
  })

  const regionIds = regionValues.itemsWithinView.map((item) => item.id)

  const values = {
    [primaryValueGroup]: true,
    ...(primaryValueGroup === 'windSpeed_minMedianMax_mps' && { windDirection_average_degrees: true }),
  }

  const { regions } = await apiFetch({
    url: '/landing-page-values-current',
    body: {
      regions: {
        regionIds: regionIds,
        values: values,
      },
    },
  })

  const getValueProp = (valueInMetric: number | null, windDirection?: number | null) => {
    let valueInUserUnits

    if (primaryValueGroup === 'temperature_minMedianMax_in_c') {
      valueInUserUnits = unitConverters.temperature(valueInMetric, { decimalPlaces: 1 }).value()
    } else if (
      primaryValueGroup === 'precipitation_next24HoursSumMinMedianMax_mm' ||
      primaryValueGroup === 'precipitation_following7DaysSumMinMedianMax_mm'
    ) {
      valueInUserUnits = unitConverters.precipitation(valueInMetric, { decimalPlaces: 2 }).value()
    } else if (primaryValueGroup === 'windSpeed_minMedianMax_mps') {
      valueInUserUnits = unitConverters.windSpeed(valueInMetric).value()
    }

    const containerCSS = getHeatmapCSS({
      value: Number(valueInMetric),
      heatmapColoring: heatmapColoring,
      heatmapExtremes: heatmapExtremes,
    })

    return {
      containerCSS,
      value: (
        <div>
          {valueInUserUnits}
          {!isNil(windDirection) && windDirection !== 0 ? (
            <span css={{ marginLeft: 1 }}>
              <IconWindDirection windDirection={windDirection} />
            </span>
          ) : (
            ''
          )}
        </div>
      ),
    }
  }

  const itemsWithinViewThatNowHaveValues = Object.entries(regions).map(([id, valObj]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valueInMetric = valObj[primaryValueGroup]?.median_value

    const windDirection =
      primaryValueGroup === 'windSpeed_minMedianMax_mps'
        ? (valObj['windDirection_average_degrees']?.value as number | null)
        : undefined

    return {
      id,
      value: getValueProp(valueInMetric, windDirection),
    }
  })

  regionalValuesCache.process({
    cacheKey: primaryValueGroup,
    itemIdsWithinView: regionValues.itemIdsWithinView,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    itemsWithinViewThatNowHaveValues,
  })
}
