import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { translate } from 'i18n/i18n'
import { useEffect, useMemo } from 'react'
import { useSlowlyRenderText } from 'utils/useSlowlyRenderText'

export const Subcategory = ({
  categoryHasBeenOpened,
  index,
  previousCategoriesRendered,
  setRenderedSubCategoryIndexes,
  subcategory,
}: {
  categoryHasBeenOpened: boolean
  index: number
  previousCategoriesRendered: boolean
  setRenderedSubCategoryIndexes: React.Dispatch<React.SetStateAction<Record<number, true>>>
  subcategory: SharedTypes.LandingPage.TProductDictionaryWithFullDetails[SharedTypes.LandingPage.TProductId]['s']['categories'][number]['subcategories'][number]
}) => {
  const citationRaw = useMemo(() => {
    if (!subcategory.citation) return ''

    return Object.entries(subcategory.citation)
      .map(([key, value]) => `${`${key}${key.includes(':') ? '' : ':'}`.toUpperCase()} ${value}`)
      .join('; ')
  }, [subcategory.citation])

  const name = useSlowlyRenderText(
    !categoryHasBeenOpened || !previousCategoriesRendered ? '' : subcategory.name,
    10,
  )

  const summary = useSlowlyRenderText(
    !categoryHasBeenOpened || !previousCategoriesRendered || !name.isFullyRendered
      ? ''
      : subcategory.summary.split('\\n').join('\n\n'),
    10,
  )

  const citation = useSlowlyRenderText(
    !categoryHasBeenOpened || !previousCategoriesRendered || !summary.isFullyRendered ? '' : citationRaw,
    10,
  )

  useEffect(() => {
    if (
      categoryHasBeenOpened &&
      name.isFullyRendered &&
      previousCategoriesRendered &&
      summary.isFullyRendered
    ) {
      setRenderedSubCategoryIndexes((rsc) => ({ ...rsc, [index]: true }))
    }
  }, [categoryHasBeenOpened, name.isFullyRendered, previousCategoriesRendered, summary.isFullyRendered])

  return (
    <div
      key={subcategory.name}
      css={{
        display: previousCategoriesRendered ? 'flex' : 'none',
        fontSize: 15,
        flexDirection: 'column',
        marginTop: previousCategoriesRendered && !!index ? 12 : 0,
      }}
    >
      {!!name.textRenderedSoFar && <div css={{ fontWeight: 700 }}>{name.textRenderedSoFar}</div>}
      {name.isFullyRendered && !!summary.textRenderedSoFar && (
        <div css={{ marginTop: 12, lineHeight: '1.7em', whiteSpace: 'pre-wrap' }}>
          {summary.textRenderedSoFar}
        </div>
      )}
      {summary.isFullyRendered && !!citation.textRenderedSoFar && (
        <div css={{ marginTop: 12, lineHeight: '1.7em', whiteSpace: 'pre-wrap', textAlign: 'right' }}>
          <span css={{ marginRight: 6 }}>
            <em>{translate.phrases.placeholder('Citations:')}</em>
          </span>
          {citation.textRenderedSoFar}
        </div>
      )}
    </div>
  )
}
