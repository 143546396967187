import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'

export const IconCheck = () => (
  <svg {...makeBaseSvgIconProps('0 0 16 16')}>
    <path
      d="M5.08471 11.6237L1.29164 7.83059L0 9.11313L5.08471 14.1978L16 3.28255L14.7175 2L5.08471 11.6237Z"
      fill="currentColor"
    />
  </svg>
)
