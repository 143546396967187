import { useMantineTheme } from '@mantine/core'
import * as Sentry from '@sentry/react'
import { translate } from 'i18n/i18n'
import React from 'react'
import type { ButtonProps, MantineButtonProps } from '../../Button'
import { ChildContent } from '../../ChildContent/ChildContent'
import { useCssOverrides } from './useCssOverrides'

export type STATE = 'default' | 'actioning' | 'done' | 'error'

export const useAsyncButtonProps = (props: ButtonProps): MantineButtonProps => {
  const minimumVisualLoadingMilliseconds = props.asyncOptions?.minimumVisualLoadingMilliseconds ?? 1000
  const successMilliseconds = props.asyncOptions?.successMilliseconds ?? 2000
  const errorMilliseconds = props.asyncOptions?.errorMilliseconds ?? 2000
  const theme = useMantineTheme()
  const [state, setState] = React.useState<STATE>('default')

  const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setState('actioning')

    try {
      const promises = await Promise.allSettled([
        props.onClick ? props.onClick(e) : Promise.resolve(),
        new Promise((resolve) => setTimeout(resolve, minimumVisualLoadingMilliseconds)),
      ])

      promises.forEach((r) => {
        if (r.status === 'rejected') throw r.reason
      })

      setState('done')

      await new Promise((resolve) => setTimeout(resolve, successMilliseconds))
    } catch (error) {
      Sentry.captureException(error)

      setState('error')

      await new Promise((resolve) => setTimeout(resolve, errorMilliseconds))
    } finally {
      setState('default')
    }
  }

  const cssOverrides = useCssOverrides({ variant: props.variant, state, theme })

  const mantineProps = {
    ...(() => {
      const { asyncOptions, variant, ...restOfProps } = props

      return restOfProps
    })(),
    leftIcon: null, // icons are put into the children for consistency and to deal with some mantine styling issues.
    rightIcon: null, // icons are put into the children for consistency and to deal with some mantine styling issues.
    onClick,
    css: cssOverrides,
    children: <ChildContent leftIcon={props.leftIcon} content={props.children} rightIcon={props.rightIcon} />,
  }

  if (state === 'actioning') {
    return {
      ...mantineProps,
      children: (
        <ChildContent
          leftIcon={null}
          content={props.asyncOptions?.loadingContent ?? translate.phrases.landingPage('Loading...')}
          rightIcon={props.rightIcon}
        />
      ),
      loading: true,
      loaderProps: { variant: 'dots', ...props.loaderProps },
    }
  }

  if (state === 'done') {
    return {
      ...mantineProps,
      children: (
        <ChildContent
          leftIcon={null}
          content={props.asyncOptions?.successContent ?? translate.phrases.landingPage('Done')}
          rightIcon={props.rightIcon}
        />
      ),
      disabled: true,
    }
  }

  if (state === 'error') {
    return {
      ...mantineProps,
      children: (
        <ChildContent
          leftIcon={null}
          content={props.asyncOptions?.errorContent ?? translate.phrases.landingPage('Error')}
          rightIcon={props.rightIcon}
        />
      ),
      disabled: true,
    }
  }

  return mantineProps
}
