import type { MantineThemeOverride } from '@mantine/core'
import { colors } from './colors'

export const customTheme: MantineThemeOverride = {
  colorScheme: 'light',
  fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
  black: colors.midnight,
  colors: {
    primary: [colors.primary, colors.primaryLight],
    secondary: [colors.secondary100, colors.secondary500],
    midnight: [colors.midnight],
    grey: [colors.grey50, colors.grey200, colors.grey500, colors.grey800],
    red: [colors.red],
    yellow: [colors.yellow],
    green: [colors.green],
    white: [colors.white],
  },
  radius: {
    xs: '0.187rem',
    sm: '0.187rem',
  },
  breakpoints: {
    xs: '28em',
    sm: '44em',
    md: '56em',
    lg: '64em',
    xl: '75em',
  },
  headings: {
    sizes: {
      h1: {
        fontWeight: 700,
        lineHeight: '2rem',
      },
      h2: {
        fontWeight: 700,
        lineHeight: '2rem',
      },
      h3: {
        fontWeight: 700,
        lineHeight: '2rem',
      },
      h4: {
        fontWeight: 700,
        lineHeight: '2rem',
      },
      h5: {
        fontWeight: 700,
        lineHeight: '2rem',
      },
      h6: {
        fontWeight: 700,
        lineHeight: '2rem',
      },
    },
  },
  components: {
    InputWrapper: {
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      styles: (theme) => ({
        label: {
          color: theme.colors.midnight[0],
          fontSize: '16px',
          fontWeight: 500,
          fontStyle: 'normal',
          lineHeight: '19px',
        },
        error: {
          'color': theme.colors.red[0],
          'fontStyle': 'italic',
          'fontSize': '14px',
          'lineHeight': '16px',
          'margin': '10px 0',
          '& + .mantine-Input-wrapper input': {
            borderColor: theme.colors.red[0],
          },
        },
      }),
    },
    Input: {
      styles: (theme) => ({
        // TODO: check & refactor other inputs in the app. e.g. createStyles usage not needed in login, etc.
        invalid: {
          borderColor: theme.colors.red[0],
        },
        input: {
          'border': `1px solid ${theme.colors.grey[1]}`,
          'borderRadius': '3px',
          '&:focus': {
            border: `1px solid ${theme.colors.midnight[0]}`,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
          },
          '&:disabled': {
            backgroundColor: theme.colors.grey[0],
          },
          '&::placeholder': {
            color: theme.colors.grey[2],
          },
        },
      }),
    },
    MonthPickerInput: {
      styles: {
        pickerControl: {
          '&[data-selected="true"]': {
            backgroundColor: colors.primary,
          },
        },
      },
    },
    Select: {
      styles: {
        item: {
          wordBreak: 'break-word', // override the default 'break-all'
        },
      },
    },
    Slider: { styles: { bar: { backgroundColor: colors.primary } } },
  },
}
