import { ActionIcon, Autocomplete } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { IconClose } from 'App/components/icons/IconClose/IconClose'
import { IconSearch } from 'App/components/icons/IconSearch/IconSearch'
import { translate } from 'i18n/i18n'
import { CountrySelector } from './components/CountrySelector/CountrySelector'
import { IntroductoryText } from './components/IntroductoryText/IntroductoryText'

export const ProductSearchCard = ({
  allTheWorld,
  autocompleteData,
  defaultProductCountryCode,
  panelHeight,
  resetProductForm,
  searchText,
  setAllTheWorld,
  setSearchText,
  setSelectedProduct,
  setShowIntroductoryText,
  showIntroductoryText,
}: {
  allTheWorld: boolean
  autocompleteData: { label: string; value: string }[]
  defaultProductCountryCode: SharedTypes.LandingPage.TProductCountry
  panelHeight: number
  resetProductForm: () => void
  searchText: string
  setAllTheWorld: React.Dispatch<React.SetStateAction<boolean>>
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  setSelectedProduct: React.Dispatch<React.SetStateAction<SharedTypes.LandingPage.TProductId | null>>
  setShowIntroductoryText: React.Dispatch<React.SetStateAction<boolean>>
  showIntroductoryText: boolean
}) => {
  const autocompleteMaxVisibleItems = Math.floor((panelHeight - 130) / 38)

  return (
    <div css={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <IntroductoryText showIntroductoryText={showIntroductoryText} />

      <div css={{ fontSize: 14, marginBottom: 6, display: 'flex', alignItems: 'center' }}>
        <translate.Phrases.placeholder
          k="You are currently viewing products related to <countrySelector/>"
          c={{
            countrySelector: (
              <CountrySelector
                allTheWorld={allTheWorld}
                defaultProductCountryCode={defaultProductCountryCode}
                setAllTheWorld={setAllTheWorld}
              />
            ),
          }}
        />
      </div>

      <Autocomplete
        data={autocompleteData}
        dropdownPosition="bottom"
        limit={autocompleteMaxVisibleItems}
        onItemSubmit={(item) => setSelectedProduct(item.value as SharedTypes.LandingPage.TProductId)}
        filter={() => true} // we are doing our own filtering, so this'll override Mantine's
        rightSection={
          showIntroductoryText ? null : (
            <ActionIcon css={{ fontSize: 10 }} onClick={resetProductForm}>
              <IconClose />
            </ActionIcon>
          )
        }
        styles={{ input: { cursor: 'pointer' } }}
        onFocus={() => setShowIntroductoryText(false)}
        onChange={setSearchText}
        placeholder={translate.phrases.placeholder('Search for a product')}
        icon={<IconSearch />}
        value={searchText}
      />
    </div>
  )
}
