import { IconLabel } from 'App/components/icons/IconLabel/IconLabel'
import { IconMoth } from 'App/components/icons/IconMoth/IconMoth'
import { IconWeather } from 'App/components/icons/IconWeather/IconWeather'
import { MapButton } from 'App/components/Map/components/Panels/components/PanelButtons/components/MapButton/MapButton'
import type { TContainerProps } from 'App/components/Map/components/Panels/utils/types'
import { usePanelPositions } from 'App/components/Map/components/Panels/utils/usePanelPositions'
import { translate } from 'i18n/i18n'
import { globalStore } from 'stores/globalStore'

export const PanelButtons = (props: TContainerProps) => {
  const panelPositions = usePanelPositions(props)
  const detailPanel = globalStore.useSelector((s) => s.detailPanel)
  const buttonsBottomGap = panelPositions.buttons.buttonsBottomGap
  const calculateBottom = (gap: number, multiplier: number): number => gap * 2 * multiplier

  return (
    <>
      <div
        css={{
          position: 'absolute',
          bottom: calculateBottom(buttonsBottomGap, 3),
          left: panelPositions.buttons.left,
        }}
      >
        <MapButton
          icon={<IconWeather />}
          isActive={detailPanel.isOpen && detailPanel.type === 'WEATHER'}
          label={translate.phrases.landingPage('Weather')}
          onClick={() => {
            globalStore.setState((s) => ({ ...s, detailPanel: { isOpen: true, type: 'WEATHER' } }))
          }}
        />
      </div>
      <div
        css={{
          position: 'absolute',
          bottom: calculateBottom(buttonsBottomGap, 2),
          left: panelPositions.buttons.left,
        }}
      >
        <MapButton
          icon={<IconLabel />}
          isActive={detailPanel.isOpen && detailPanel.type === 'LABEL_LOOKUP'}
          label={translate.phrases.landingPage('Label Lookup')}
          onClick={() => {
            globalStore.setState((s) => ({
              ...s,
              primaryValueGroup: 'windSpeed_minMedianMax_mps',
              detailPanel: { isOpen: true, type: 'LABEL_LOOKUP' },
            }))
          }}
        />
      </div>
      <div
        css={{
          position: 'absolute',
          bottom: calculateBottom(buttonsBottomGap, 1),
          left: panelPositions.buttons.left,
        }}
      >
        <MapButton
          icon={<IconMoth />}
          isActive={detailPanel.isOpen && detailPanel.type === 'PEST'}
          label={translate.phrases.landingPage('Pest')}
          onClick={() => {
            globalStore.setState((s) => ({ ...s, detailPanel: { isOpen: true, type: 'PEST' } }))
          }}
        />
      </div>
    </>
  )
}
