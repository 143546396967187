import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'

export const IconWeather = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <path
        d="M7.84637 4.22456C8.14397 4.22456 8.38512 3.99741 8.38512 3.71709V2.50747C8.38512 2.22715 8.14397 2 7.84637 2C7.54876 2 7.30762 2.22715 7.30762 2.50747V3.71709C7.30762 3.99741 7.54898 4.22456 7.84637 4.22456Z"
        fill="white"
      />
      <path
        d="M1.82313 8.88452H0.538751C0.241145 8.88452 0 9.11167 0 9.39199C0 9.67232 0.241145 9.89947 0.538751 9.89947H1.82313C2.12074 9.89947 2.36188 9.67232 2.36188 9.39199C2.36188 9.11167 2.12074 8.88452 1.82313 8.88452Z"
        fill="white"
      />
      <path
        d="M3.97409 5.73884C4.18463 5.54072 4.18463 5.21939 3.97409 5.02127L3.06619 4.16607C2.85586 3.96775 2.51451 3.96775 2.30439 4.16607C2.09385 4.36419 2.09385 4.68552 2.30439 4.88364L3.21229 5.73884C3.31746 5.83789 3.45538 5.88742 3.5933 5.88742C3.73122 5.88742 3.86892 5.83789 3.97409 5.73884Z"
        fill="white"
      />
      <path
        d="M12.1168 5.88752C12.2548 5.88752 12.3925 5.838 12.4979 5.73914L13.4064 4.88395C13.6169 4.68583 13.6169 4.3645 13.4066 4.16618C13.1961 3.96765 12.8549 3.96786 12.6446 4.16597L11.7361 5.02117C11.5255 5.21928 11.5255 5.54062 11.7358 5.73894C11.8408 5.838 11.9789 5.88752 12.1168 5.88752Z"
        fill="white"
      />
      <path
        d="M17.8415 10.7808C17.1071 9.1303 15.373 8.03537 13.4589 8.03537C13.3305 8.03537 13.2031 8.04329 13.0762 8.05303C12.1704 6.45713 10.3986 5.44482 8.45649 5.44482H8.45605C5.56835 5.44482 3.21875 7.65781 3.21875 10.3781C3.21875 11.5319 3.64393 12.63 4.4165 13.5118C4.23914 13.9046 4.14195 14.3309 4.14195 14.7685C4.14195 16.0908 4.97939 17.2608 6.27885 17.7596C6.58422 17.9167 6.92751 17.9999 7.27425 17.9999H16.133C16.3097 17.9999 16.488 17.9778 16.6634 17.9339C18.5981 17.6002 19.9995 16.0183 19.9995 14.1634C19.9993 12.7498 19.1593 11.4442 17.8415 10.7808ZM4.29604 10.3779C4.29604 8.21725 6.16205 6.45957 8.45584 6.45957H8.45627C9.88913 6.45957 11.205 7.15379 11.9616 8.2684C11.2843 8.48093 10.6598 8.8319 10.1417 9.30832C9.84711 9.23788 9.54756 9.20256 9.2478 9.20256C7.44385 9.20256 5.89785 10.4641 5.61382 12.1187C5.40026 12.2592 5.20696 12.4218 5.03477 12.6008C4.55744 11.9508 4.29604 11.1815 4.29604 10.3779ZM16.4507 16.9387C16.4337 16.9415 16.4166 16.9452 16.3998 16.9496C16.3104 16.9732 16.2205 16.9852 16.1328 16.9852H7.27382C7.09883 16.9852 6.93225 16.9432 6.7786 16.8605C6.75619 16.8484 6.73313 16.838 6.70921 16.8291C5.80411 16.4921 5.21924 15.6834 5.21924 14.7689C5.21924 13.9862 5.66748 13.253 6.38898 12.8558C6.54026 12.7725 6.63896 12.6252 6.65383 12.4607C6.76761 11.2032 7.90696 10.2179 9.24759 10.2179C9.54088 10.2179 9.83504 10.2666 10.1221 10.3631C10.329 10.4321 10.5606 10.3762 10.7067 10.221C11.4041 9.47721 12.4075 9.05052 13.4589 9.05052C14.9958 9.05052 16.3824 9.96337 16.9097 11.3218C16.9586 11.4478 17.0586 11.5507 17.1881 11.6074C18.2415 12.0698 18.922 13.0734 18.922 14.1636C18.9218 15.5332 17.8827 16.7004 16.4507 16.9387Z"
        fill="white"
      />
    </svg>
  )
}
