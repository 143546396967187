import React from 'react'
import { IconAlma } from '../icons/IconAlma/IconAlma'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const SummaryStatementWrapper = ({
  children,
  title = translate.phrases.landingPage('Summary'),
}: {
  children: React.ReactNode
  title?: string
}) => {
  return (
    <div
      css={{
        background: colors.grey200,
        color: colors.black,
        borderRadius: 3,
        padding: 10,
        fontSize: 14,
      }}
    >
      <div
        css={{
          display: 'flex',
          gap: 12,
          alignItems: 'center',
        }}
      >
        <span css={{ fontSize: 40 }}>
          <IconAlma />
        </span>
        <div css={{ lineHeight: 0, paddingBottom: 13, fontWeight: 700 }}>{title}</div>
      </div>
      <div css={{ margin: '0 5px', lineHeight: '1.6em' }}>{children}</div>
    </div>
  )
}
