import { IconSuccess } from 'App/components/icons/IconSuccess/IconSuccess'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const SuccessMessage = (props: { email: string }) => {
  return (
    <div
      css={{
        backgroundColor: colors.grey200,
        fontSize: 14,
        padding: 10,
        borderRadius: 3,
        display: 'flex',
        gap: 12,
        alignItems: 'center',
      }}
    >
      <span css={{ color: colors.green500, fontSize: 40, display: 'flex' }}>
        <IconSuccess />
      </span>
      <span>
        {translate.phrases.placeholder(
          'Please check your email. We have sent a link to {{email}} with instructions to set up your account.',
          { email: props.email },
        )}
      </span>
    </div>
  )
}
