import { useInterval } from '@mantine/hooks'
import { useEffect, useState } from 'react'

export const useSlowlyRenderText = (completeTextString: string, interval = 20) => {
  const [chunkWeAreOn, setChunkWeAreOn] = useState(1)
  const chunksSeparatedBySpaces = completeTextString.split(' ')

  const { start, stop } = useInterval(() => {
    setChunkWeAreOn((cwao) => {
      if (cwao === chunksSeparatedBySpaces.length) {
        stop()

        return cwao
      }

      return !Math.floor(Math.random() * 3) ? cwao : cwao + 1
    })
  }, interval)

  useEffect(() => {
    setChunkWeAreOn(1)

    start()

    return () => stop()
  }, [completeTextString, interval])

  return {
    completeTextString,
    isFullyRendered: chunkWeAreOn === chunksSeparatedBySpaces.length,
    textRenderedSoFar: chunksSeparatedBySpaces.slice(0, chunkWeAreOn).join(' '),
  }
}
