import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'

export const IconArrowRightCircle = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 16 16')}>
      <path
        d="M7.99993 4.2666L7.34193 4.9246L9.94593 7.53327H4.2666V8.4666H9.94593L7.34193 11.0753L7.99993 11.7333L11.7333 7.99993L7.99993 4.2666Z"
        fill="currentColor"
      />
      <circle cx="8" cy="8" r="7.5" stroke="currentColor" />
    </svg>
  )
}
