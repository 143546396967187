import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const GeneralDisclaimer = () => {
  return (
    <div css={{ background: colors.grey200, color: colors.black, borderRadius: 3, padding: 5, fontSize: 10 }}>
      {translate.phrases.placeholder(
        'The material and content summarized on this page is for general use information only. Semios does not provide any guarantee  or assurance that the information obtained through the service is accurate, current or correct, and is therefore not liable for any loss resulting, directly or indirectly, from reliance upon this service. Product label summarizations may contain errors and do not replace official manufacture issued label. Users of this feature must read and follow the actual product label affixed to the container before use of the product.',
      )}
    </div>
  )
}
