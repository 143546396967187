export type TSettings = {
  googleMapsApiKey: string
  hubApiUrl: string
  hubAppUrl: string
}

export let settings: TSettings

export const loadSettings = (s: TSettings) => {
  settings = s
}
