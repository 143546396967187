export const usePanelPositions = (o: { containerHeight: number; containerWidth: number }) => {
  const googleLogoHeight = 26
  const gap = 10
  const mapControls = { top: gap, right: gap, width: 200, height: 108 }
  const detailsWideWidth = 500
  const isSmallHeightContainer = o.containerHeight <= 260

  return {
    mapControls,
    buttons: {
      buttonsBottomGap: isSmallHeightContainer ? 24 : 32,
      bottom: isSmallHeightContainer ? googleLogoHeight + 6 : googleLogoHeight + 32,
      left: 34,
    },
    details: {
      bottom: gap,
      right: gap,
      left: o.containerWidth <= detailsWideWidth + gap ? gap : o.containerWidth - detailsWideWidth,
      maxHeight: o.containerHeight - mapControls.height - gap - gap - gap,
    },
  }
}
