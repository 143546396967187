import { isNil } from '@semios/app-platform-common'
import type { RefObject } from 'react'
import { useEffect } from 'react'

type TSelectionState = 'base' | 'selected' | 'hovered'

const stateZIndexes: Record<TSelectionState, number> = {
  base: 10,
  selected: 20,
  hovered: 30,
}

const mItemZIndexes = {
  M_CurrentLocation: 40,
  M_Region: 1,
} as const

export const useSetMapOverlayZIndex = ({
  baseZIndex,
  ref,
  mItem,
  isSelected,
  isHighlighted,
  isHovered,
}: {
  baseZIndex?: number
  ref: RefObject<HTMLDivElement>
  mItem: keyof typeof mItemZIndexes
  isSelected?: boolean
  isHighlighted?: boolean
  isHovered?: boolean
}) => {
  return useEffect(() => {
    if (ref.current) {
      const reactOverlayDiv = ref.current.parentElement as HTMLDivElement

      let selectionState: TSelectionState = 'base'

      if (isHovered) selectionState = 'hovered'
      else if (isSelected) selectionState = 'selected'

      const stateZIndex = stateZIndexes[selectionState]
      const mItemZIndex = mItemZIndexes[mItem] || 0

      reactOverlayDiv.style.zIndex = (stateZIndex + (isNil(baseZIndex) ? mItemZIndex : baseZIndex)).toString()
    }
  }, [isSelected, isHighlighted, isHovered, mItem])
}
