import { Accordion } from '@mantine/core'
import { translate } from 'i18n/i18n'
import { colors } from 'settings/colors'

export const IntroductoryText = ({ showIntroductoryText }: { showIntroductoryText: boolean }) => {
  return (
    <Accordion
      value={showIntroductoryText ? 'introductory-text' : null}
      styles={{ content: { padding: 0 }, item: { borderBottom: 'none' } }}
    >
      <Accordion.Item value="introductory-text">
        <Accordion.Panel>
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <p css={{ fontWeight: 400, color: colors.grey800 }}>
              {translate.phrases.placeholder(
                'Search a product and learn how Alma can help summarize important product label information. These summaries do not replace the official manufacturer issued label affixed to the container which must be read prior to use.',
              )}
            </p>
            <div css={{ marginTop: 18, marginBottom: 12, fontSize: 18, fontWeight: 700 }}>
              {translate.phrases.placeholder('Enter a product name in the search bar below to get started')}
            </div>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}
