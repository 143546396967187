import { translate } from 'i18n/i18n'
import type { TWeatherForecastDaily, TWeatherIcon, TTemperatureMinMedianMax } from '../../types'
import { unitConverters } from '../../../../../utils/unitConverters'
import { WeatherIcon } from './WeatherIcon/WeatherIcon'

type TCurrentWeatherProps = {
  iconCode: TWeatherIcon
  weatherForecastDaily: TWeatherForecastDaily
  currentTemperature: TTemperatureMinMedianMax
}

export const CurrentWeather = (props: TCurrentWeatherProps) => {
  const todaysWeather = props.weatherForecastDaily?.value?.[0]

  return (
    <div
      css={{
        'marginTop': 12,
        'display': 'flex',
        'flexDirection': 'row',
        'alignItems': 'center',
        'alignContent': 'center',
        'justifyContent': 'center',
        '& > div': {
          margin: '0 10px',
        },
      }}
    >
      <div>
        <WeatherIcon weatherCondition={props.iconCode?.value} />
      </div>

      <div
        css={{
          margin: '0 10px',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            fontSize: 53,
            fontWeight: 200,
          }}
        >
          {unitConverters.temperature(props.currentTemperature?.median_value).valueWithSuffix()}
        </div>
        <div css={{ display: 'flex', gap: 12 }}>
          <span>
            {translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.abbreviations('High'),
              value: unitConverters
                .temperature(todaysWeather?.temperature_minAverageMax_in_c?.max_value)
                .valueWithSuffix(),
            })}
          </span>
          <span>
            {translate.phrases.templates('{{label}}: {{value}}', {
              label: translate.phrases.abbreviations('Low'),
              value: unitConverters
                .temperature(todaysWeather?.temperature_minAverageMax_in_c?.min_value)
                .valueWithSuffix(),
            })}
          </span>
        </div>
      </div>
    </div>
  )
}
