import type { TRegionalBadgeProps } from 'App/components/Map/components/RegionalBadge/RegionalBadge'
import { RegionalBadge } from 'App/components/Map/components/RegionalBadge/RegionalBadge'
import type { ReactNode } from 'react'
import type { ReactComponentOverlayView } from 'utils/makeReactComponentOverlayView'
import { makeReactComponentOverlayView } from 'utils/makeReactComponentOverlayView'
import { MapItemsCache } from 'utils/MapItemsCache'

export type REGION_META = {
  id: string
  latLng: google.maps.LatLng
  name: string
}

export type REGION_VALUE = {
  value: { value: ReactNode; containerCSS: { background: string; color: string } }
}

// stores items
export class RegionalValuesCache extends MapItemsCache<
  REGION_META,
  REGION_VALUE,
  ReactComponentOverlayView<TRegionalBadgeProps>
> {
  constructor(options: { maps: typeof google.maps; map: google.maps.Map }) {
    super({
      createOverlay: (o) => {
        return makeReactComponentOverlayView({
          component: RegionalBadge,
          latLng: o.meta.latLng,
          maps: o.maps,
          map: o.map,
        })
      },
      renderOverlay: (o) => {
        // props passed to component here
        // (extra props need to be passed in value key unless MapItemsCache is updated)
        o.overlay.render({ meta: o.meta, value: o.value })
      },
      hideOverlay: (overlay) => {
        overlay.setMap(null)
      },
      maps: options.maps,
      map: options.map,
    })
  }
}
