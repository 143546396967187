import type { TLngLat } from 'App/components/Map/Map'

export const lngLatStringToLngLatObject = (input: TLngLat) => {
  const [lng, lat] = input
    .replace(/[^\d.-\s]/g, '')
    .split(' ')
    .map(Number)

  return { lng, lat }
}
