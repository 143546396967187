import { Accordion } from '@mantine/core'
import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { SummaryStatementWrapper } from 'App/components/SummaryStatementWrapper/SummaryStatementWrapper'
import { translate } from 'i18n/i18n'
import { useEffect, useState } from 'react'
import { useApi } from 'utils/useApi'
import { BackButton } from './components/BackButton/BackButton'
import { cleanText } from './utils/cleanText'
import { useSlowlyRenderText } from 'utils/useSlowlyRenderText'
import { Button } from 'App/components/Button/Button'
import { IconArrowRightCircle } from 'App/components/icons/IconArrowRightCircle/IconArrowRightCircle'
import { Subcategories } from './components/Subcategories/Subcategories'
import { getAgworldURL } from 'utils/getAgworldURL'
import { GeneralDisclaimer } from 'App/components/GeneralDisclaimer/GeneralDisclaimer'

export const SelectedProductCard = ({
  resetProductForm,
  selectedProduct,
  setPanelTitle,
}: {
  resetProductForm: () => void
  selectedProduct: SharedTypes.LandingPage.TProductId
  setPanelTitle: React.Dispatch<React.SetStateAction<React.ReactNode>>
}) => {
  const [openedCategories, setOpenedCategories] = useState<string[]>([])
  const [categoriesThatHaveBeenOpened, setCategoriesThatHaveBeenOpened] = useState<Record<string, true>>({})

  useEffect(() => {
    const opened = openedCategories.reduce((acc, val) => ({ ...acc, [val]: true }), {})

    setCategoriesThatHaveBeenOpened((prev) => ({ ...prev, ...opened }))
  }, [openedCategories])

  useEffect(() => {
    setPanelTitle(<BackButton resetProductForm={resetProductForm} />)
  }, [])

  const { data, loading } = useApi({
    url: '/landing-page-product-details',
    body: { products: [selectedProduct] },
    shaper: ({ data }) => data?.[selectedProduct],
  })

  useEffect(() => {
    // update the panel title when the product name is loaded
    if (data?.n) {
      setPanelTitle(
        <div css={{ display: 'flex', gap: 12 }}>
          <div>
            <BackButton resetProductForm={resetProductForm} />
          </div>
          <div>{data.n}</div>
        </div>,
      )
    }
  }, [data?.n])

  const content = data?.s
  const summaryText = useSlowlyRenderText(cleanText(content?.summary ?? ''))

  if (loading) return null

  if (!data || !content) return <div>{translate.phrases.placeholder('Product not found')}</div>

  return (
    <>
      <SummaryStatementWrapper>
        <div css={{ whiteSpace: 'pre-wrap' }}>{summaryText.textRenderedSoFar}</div>
      </SummaryStatementWrapper>
      {!!summaryText.isFullyRendered && (
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Accordion
            multiple
            value={openedCategories}
            onChange={setOpenedCategories}
            styles={{
              item: { marginTop: 6, border: 'none', padding: 0 },
              content: { paddingTop: 14, marginTop: 0, marginBottom: 12, background: 'rgba(239, 239, 239)' },
              label: { paddingTop: 12, paddingBottom: 12, fontWeight: 600 },
              control: { background: 'rgba(239, 239, 239) !important', borderBottom: '4px solid white' },
              chevron: { '> svg': { width: '30px !important', height: '30px !important' } },
            }}
          >
            {content.categories.map((category) => (
              <Accordion.Item value={category.name} key={category.name}>
                <Accordion.Control>{category.name}</Accordion.Control>
                <Accordion.Panel>
                  <Subcategories
                    categoryHasBeenOpened={categoriesThatHaveBeenOpened[category.name]}
                    subcategories={category.subcategories}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
          <a
            css={{ marginTop: 12, marginBottom: 12 }}
            href={getAgworldURL()}
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="primary" css={{ width: '100%' }}>
              <div css={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center' }}>
                <div>{translate.phrases.landingPage('View Full Details on Agworld')}</div>
                <IconArrowRightCircle />
              </div>
            </Button>
          </a>
          <GeneralDisclaimer />
        </div>
      )}
    </>
  )
}
