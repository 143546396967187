import { isNil } from '@semios/app-platform-common'
import { translate } from 'i18n/i18n'
import { getUserUnits } from 'stores/utils/getUserUnits'

const MILES_PER_KILOMETRE = 0.6213711922374
const KPH_PER_MPS = 3.6

export const unitConverters = {
  temperature: (value?: number | null, options: { decimalPlaces?: number } = {}) => {
    const userUnits = getUserUnits()

    let convertedValue: number | null

    const shouldConvertFromCToF = userUnits === 'IMPERIAL'

    if (isNil(value)) {
      convertedValue = null
    } else if (shouldConvertFromCToF) {
      convertedValue = value * 1.8 + 32
    } else {
      // assume there is no conversion required
      convertedValue = Number(value)
    }

    const unitSymbol = shouldConvertFromCToF
      ? translate.measurements.degreesFahrenheit.unit()
      : translate.measurements.degreesCelsius.unit()

    return {
      value: () => {
        if (convertedValue === null) return translate.phrases.templates('-')

        if (userUnits === 'IMPERIAL') {
          return translate.measurements.degreesFahrenheit.value(convertedValue, options.decimalPlaces ?? 0)
        } else {
          return translate.measurements.degreesCelsius.value(convertedValue, options.decimalPlaces ?? 0)
        }
      },
      suffix: () => unitSymbol,
      valueWithSuffix: () => {
        if (convertedValue === null) return null

        if (userUnits === 'IMPERIAL') {
          return translate.measurements.degreesFahrenheit
            .valueWithUnit(convertedValue, options.decimalPlaces ?? 0)
            .replace(' ', ' ')
        } else {
          return translate.measurements.degreesCelsius
            .valueWithUnit(convertedValue, options.decimalPlaces ?? 0)
            .replace(' ', ' ')
        }
      },
    }
  },
  windSpeed: (value?: number | null, options: { decimalPlaces?: number } = {}) => {
    const userUnits = getUserUnits()
    const inputUnit = 'mps'
    const shouldConvertMPSToKPH = inputUnit === 'mps' && userUnits === 'METRIC'
    const shouldConvertMPSToMPH = inputUnit === 'mps' && userUnits === 'IMPERIAL'

    let convertedValue: number | null = null
    let unitSymbol = translate.measurements.metersPerSecond.unit()

    if (shouldConvertMPSToKPH) unitSymbol = translate.measurements.kilometersPerHour.unit()

    if (shouldConvertMPSToMPH) unitSymbol = translate.measurements.milesPerHour.unit()

    if (isNil(value)) {
      convertedValue = null
    } else if (shouldConvertMPSToKPH) {
      convertedValue = value * KPH_PER_MPS
    } else if (shouldConvertMPSToMPH) {
      convertedValue = value * MILES_PER_KILOMETRE
    } else {
      // assume there is no conversion required
      convertedValue = Number(value)
    }

    let valueAsString: string
    let valueWithSuffix: string

    if (userUnits === 'IMPERIAL') {
      valueAsString = translate.measurements.milesPerHour.value(convertedValue, options.decimalPlaces ?? 0)

      valueWithSuffix = translate.measurements.milesPerHour
        .valueWithUnit(convertedValue, options.decimalPlaces ?? 0)
        .replace(' ', ' ')
    } else {
      valueAsString = translate.measurements.kilometersPerHour.value(
        convertedValue,
        options.decimalPlaces ?? 0,
      )

      valueWithSuffix = translate.measurements.kilometersPerHour
        .valueWithUnit(convertedValue, options.decimalPlaces ?? 0)
        .replace(' ', ' ')
    }

    return {
      value: () => {
        if (convertedValue === null) return translate.phrases.templates('-')

        return valueAsString
      },
      suffix: () => unitSymbol,
      valueWithSuffix: () => {
        if (convertedValue === null) return null

        return valueWithSuffix
      },
    }
  },

  precipitation: (value?: number | null, options: { decimalPlaces?: number } = {}) => {
    const userUnits = getUserUnits()
    const defaultNumberOfDecimalPlaces = userUnits === 'IMPERIAL' ? 2 : 1
    const shouldConvertFromMMToIN = userUnits === 'IMPERIAL'

    let convertedValue: number | null

    if (isNil(value)) {
      convertedValue = null
    } else if (shouldConvertFromMMToIN) {
      convertedValue = value / 25.4
    } else {
      // assume there is no conversion required
      convertedValue = Number(value)
    }

    const decimalPlacesToUse = options.decimalPlaces ?? defaultNumberOfDecimalPlaces

    const unitSymbol = shouldConvertFromMMToIN
      ? translate.measurements.inches.unit()
      : translate.measurements.millimeters.unit()

    return {
      value: () => {
        if (convertedValue === null) return translate.phrases.templates('-')

        if (userUnits === 'IMPERIAL') {
          return translate.measurements.inches.value(convertedValue, decimalPlacesToUse)
        } else {
          return translate.measurements.millimeters.value(convertedValue, decimalPlacesToUse)
        }
      },
      suffix: () => unitSymbol,
      valueWithSuffix: () => {
        if (convertedValue === null) return null

        if (userUnits === 'IMPERIAL') {
          return translate.measurements.inches
            .valueWithUnit(convertedValue, decimalPlacesToUse)
            .replace(' ', ' ')
        } else {
          return translate.measurements.millimeters
            .valueWithUnit(convertedValue, decimalPlacesToUse)
            .replace(' ', ' ')
        }
      },
    }
  },
}
