import { useRef, useEffect, useMemo, useSyncExternalStore } from 'react'

// Inspired from https://unpkg.com/use-sync-external-store@1.2.0/cjs/use-sync-external-store-with-selector.development.js
export const useSyncExternalStoreWithSelector = <Snapshot, Selection>(
  subscribe: (onStoreChange: () => void) => () => void,
  getSnapshot: () => Snapshot,
  selector: (snapshot: Snapshot) => Selection,
  isEqual: (a: Selection, b: Selection) => boolean = Object.is,
) => {
  type INSTANCE_REF = { hasValue: boolean; value: Selection | null }

  // Use this to track the rendered snapshot.
  const instRef = useRef<INSTANCE_REF | null>(null)

  let inst: INSTANCE_REF

  if (instRef.current === null) {
    inst = {
      hasValue: false,
      value: null,
    }

    instRef.current = inst
  } else {
    inst = instRef.current
  }

  const getSelection = useMemo(() => {
    // Track the memoized state using closure variables that are local to this
    // memoized instance of a getSnapshot function. Intentionally not using a
    // useRef hook, because that state would be shared across all concurrent
    // copies of the hook/component.
    let hasMemo = false
    let memoizedSnapshot: Snapshot
    let memoizedSelection: Selection

    const memoizedSelector = (nextSnapshot: Snapshot) => {
      if (!hasMemo) {
        // The first time the hook is called, there is no memoized result.
        hasMemo = true

        memoizedSnapshot = nextSnapshot

        const nextSelection = selector(nextSnapshot)

        // Even if the selector has changed, the currently rendered selection
        // may be equal to the new selection. We should attempt to reuse the
        // current value if possible, to preserve downstream memoizations.
        if (inst.hasValue) {
          const currentSelection = inst.value as Selection

          if (isEqual(currentSelection, nextSelection)) {
            memoizedSelection = currentSelection

            return currentSelection
          }
        }

        memoizedSelection = nextSelection

        return nextSelection
      }

      // We may be able to reuse the previous invocation's result.
      const prevSnapshot = memoizedSnapshot
      const prevSelection = memoizedSelection

      if (Object.is(prevSnapshot, nextSnapshot)) {
        // The snapshot is the same as last time. Reuse the previous selection.
        return prevSelection
      }

      // The snapshot has changed, so we need to compute a new selection.
      const nextSelection = selector(nextSnapshot)

      // If a custom isEqual function is provided, use that to check if the data
      // has changed. If it hasn't, return the previous selection. That signals
      // to React that the selections are conceptually equal, and we can bail
      // out of rendering.
      if (isEqual(prevSelection, nextSelection)) {
        return prevSelection
      }

      memoizedSnapshot = nextSnapshot

      memoizedSelection = nextSelection

      return nextSelection
    }

    return () => memoizedSelector(getSnapshot())
  }, [getSnapshot, selector, isEqual])

  const value = useSyncExternalStore(subscribe, getSelection)

  useEffect(() => {
    inst.hasValue = true

    inst.value = value
  }, [value])

  return value
}
