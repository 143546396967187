import type { SharedTypes } from '@semios/app-platform-banyan-route-definitions'
import { useState } from 'react'
import { Subcategory } from './components/Subcategory/Subcategory'

export const Subcategories = ({
  categoryHasBeenOpened,
  subcategories,
}: {
  categoryHasBeenOpened: boolean
  subcategories: SharedTypes.LandingPage.TProductDictionaryWithFullDetails[SharedTypes.LandingPage.TProductId]['s']['categories'][number]['subcategories']
}) => {
  const [renderedSubCategoryIndexes, setRenderedSubCategoryIndexes] = useState<Record<number, true>>({})

  return (
    <>
      {subcategories.map((subcategory, index) => {
        return (
          <Subcategory
            categoryHasBeenOpened={categoryHasBeenOpened}
            index={index}
            key={subcategory.name}
            previousCategoriesRendered={!index ? true : renderedSubCategoryIndexes[index - 1]}
            setRenderedSubCategoryIndexes={setRenderedSubCategoryIndexes}
            subcategory={subcategory}
          />
        )
      })}
    </>
  )
}
