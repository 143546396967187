import type * as MantineTypes from '@mantine/core'
import type { PolymorphicComponentProps } from '@mantine/utils'
import type { FC } from 'react'
import React from 'react'
import { getButtonComponent } from './_utils/getButtonComponent'
import { ButtonAsync } from './ButtonAsync/ButtonAsync'
import { ChildContent } from './ChildContent/ChildContent'

export type MantineButtonProps = PolymorphicComponentProps<'button', MantineTypes.ButtonProps>

export type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'positive' | 'negative' | 'link' | 'link-negative' | 'list'
  async?: boolean
  asyncOptions?: {
    minimumVisualLoadingMilliseconds?: number
    successMilliseconds?: number
    errorMilliseconds?: number
    loadingContent?: React.ReactNode
    successContent?: React.ReactNode
    errorContent?: React.ReactNode
  }
} & Omit<MantineButtonProps, 'variant'>

export const Button: FC<ButtonProps> = (props) => {
  if (props.async === true) return <ButtonAsync {...props} />

  const { variant, ...mantineButtonProps } = props
  const ButtonComponent = getButtonComponent(variant)

  return (
    <ButtonComponent
      {...mantineButtonProps}
      leftIcon={null} // icons are put into the children for consistency and to deal with some mantine styling issues
      rightIcon={null} // icons are put into the children for consistency and to deal with some mantine styling issues
      loaderProps={{ variant: 'dots', ...props.loaderProps }}
    >
      <ChildContent
        leftIcon={props.leftIcon}
        content={props.children}
        rightIcon={props.rightIcon}
        fullWidth={props.fullWidth}
      />
    </ButtonComponent>
  )
}
