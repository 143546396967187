import { MapContext } from 'App/components/MapProvider/MapProvider'
import { useContext, useEffect, useState } from 'react'
import { globalStore } from 'stores/globalStore'
import { apiFetch } from 'utils/apiFetch'
import { useIsDarkMode } from 'utils/useIsDarkMode'
import { buildOverlay } from './utils/buildOverlay'

export const useRegionBoundary = () => {
  const mapContext = useContext(MapContext)
  const selectedRegion = globalStore.useSelector((s) => s.selectedRegion)
  const regionsGeometries = globalStore.useSelector((s) => s.regionsGeometries)
  const isDarkMode = useIsDarkMode()

  const [overlaysDictionary, setOverlaysDictionary] = useState<
    Record<string, { show: () => void; hide: () => void } | null>
  >({})

  useEffect(() => {
    if (selectedRegion) {
      if (!regionsGeometries[selectedRegion.id]) {
        apiFetch({
          url: '/landing-page-field-asset-configurations',
          body: { regions: { regionIds: [selectedRegion.id], _metaData: { geometry: true } } },
        }).then((res) => {
          if (res?.data?.regions?.[selectedRegion.id]?.geometry) {
            globalStore.setState((s) => ({
              ...s,
              regionsGeometries: {
                ...s.regionsGeometries,
                [selectedRegion.id]: String(res?.data?.regions?.[selectedRegion.id]?.geometry),
              },
            }))
          }
        })
      }
    }
  }, [selectedRegion, regionsGeometries[String(selectedRegion?.id)]])

  useEffect(() => {
    Object.entries(overlaysDictionary)
      .filter(([regionId]) => regionId !== selectedRegion?.id)
      .forEach(([, overlay]) => overlay?.hide())

    if (selectedRegion?.id && regionsGeometries?.[selectedRegion.id]) {
      if (overlaysDictionary[selectedRegion.id]) {
        overlaysDictionary[selectedRegion.id]?.show()
      } else {
        setOverlaysDictionary((d) => ({
          ...d,
          [selectedRegion.id]: buildOverlay({
            isDarkMode,
            map: mapContext.map,
            multiPolygonGeoJSONString: regionsGeometries?.[selectedRegion.id],
          }),
        }))
      }
    }
  }, [
    selectedRegion?.id,
    regionsGeometries?.[String(selectedRegion?.id)],
    overlaysDictionary[String(selectedRegion?.id)],
    mapContext.map,
  ])
}
