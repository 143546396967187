import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'

export const IconSuccess = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 20 20')}>
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <path
        d="M7.81353 12.7177L4.96873 9.87294L4 10.8348L7.81353 14.6484L16 6.46191L15.0381 5.5L7.81353 12.7177Z"
        fill="white"
      />
    </svg>
  )
}
