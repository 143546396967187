import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'
// TODO: Refactor to take no props

export const IconWindDirection = ({ windDirection }: { windDirection?: number | null }) => {
  return (
    <svg
      {...makeBaseSvgIconProps('0 0 10 10')}
      css={{ transform: `rotate(${(windDirection ?? 0) - 45}deg)` }}
    >
      <path
        d="M9.35311 0.147059L0.25076 3.95529L0.25076 4.45647L3.70605 5.79412L5.0437 9.24941L5.54488 9.24941L9.35311 0.147059Z"
        fill="currentColor"
      />
    </svg>
  )
}
