import { lngLatStringToLngLatObject } from 'App/components/Map/utils/lngLatStringToLngLatObject'
import { RegionalValuesCache } from 'App/components/Map/utils/RegionalValuesCache'
import { globalStore } from 'stores/globalStore'

export const populateCacheFromStartupData = (o: { maps: typeof google.maps; map: google.maps.Map }) => {
  const regionalValuesCache = new RegionalValuesCache({ maps: o.maps, map: o.map })

  Object.values(globalStore.getState().startupData?.allRegions ?? {}).forEach((region) => {
    regionalValuesCache.addItem({
      id: region.i,
      value: {},
      meta: {
        id: region.i,
        latLng: new o.maps.LatLng(lngLatStringToLngLatObject(region.l)),
        name: region.n,
      },
    })
  })

  return {
    regionalValuesCache,
  }
}
