import { makeBaseSvgIconProps } from 'App/components/icons/utils/makeBaseSvgIconProps'
import { colors } from 'settings/colors'

export const IconCurrentLocationMarker = () => {
  return (
    <svg {...makeBaseSvgIconProps('0 0 60 60')}>
      <circle cx="30" cy="30" r="30" fill="url(#dropshadow)" />
      <circle cx="30" cy="30" r="13" fill={colors.white} />
      <circle cx="30" cy="30" r="8.5" fill={colors.primaryLight} />
      <defs>
        <radialGradient
          id="dropshadow"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30 30) rotate(90.5824) scale(22)"
        >
          <stop stopColor={colors.midnight} stopOpacity="0.4" />
          <stop offset="1" stopColor={colors.midnight} stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
