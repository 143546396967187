import { useElementSize } from '@mantine/hooks'
import type { routes } from '@semios/app-platform-banyan-route-definitions'
import moment from 'moment-timezone'
import React from 'react'
import { unitConverters } from '../../../../../utils/unitConverters'
import type { EnumWeatherCondition } from '../../utils/weatherConditionToIcon'
import { weatherConditionToIcon } from '../../utils/weatherConditionToIcon'
import { IconWindDirection } from '../../icons/IconWindDirection'
import { colors } from 'settings/colors'
import { translate } from 'i18n/i18n'

type TDailyWeatherData = routes.LandingPageValuesCurrent.Response['regions'][string]['weatherForecastDaily']

export const WeatherForecast = ({
  dailyWeatherData,
  timezone = 'America/Los_Angeles',
}: {
  dailyWeatherData: TDailyWeatherData
  timezone?: string
}) => {
  const wrapperDiv = useElementSize()
  const isSmall = wrapperDiv.width < 330
  const data = dailyWeatherData?.value ?? []

  const sevenDaysAhead = data.filter(
    (row) =>
      moment.tz(row.timestamp, timezone).isBefore(moment.tz(timezone).add(7, 'days')) &&
      moment.tz(row.timestamp, timezone).isAfter(moment.tz(timezone)),
  )

  const shapedRows: {
    key: string
    dayLabel: string
    icon: string | null
    temperature_min: string | null
    temperature_max: string | null
    windSpeed: string | null
    windDirection: number | null
    precipitation: string | null
  }[] = sevenDaysAhead.map((row) => {
    const dayLabel = moment.tz(row.timestamp, timezone).format(isSmall ? 'dd' : 'ddd')
    const icon = row.weatherIcon

    const temperature_min = unitConverters
      .temperature(row.temperature_minAverageMax_in_c?.min_value, { decimalPlaces: 0 })
      .valueWithSuffix()

    const temperature_max = unitConverters
      .temperature(row.temperature_minAverageMax_in_c?.max_value, { decimalPlaces: 0 })
      .valueWithSuffix()

    const windSpeed = unitConverters
      .windSpeed(row.windSpeed_minAverageMax_mps?.average_value, { decimalPlaces: 0 })
      .valueWithSuffix()

    const windDirection = row.windDirection_average_degrees

    const precipitation = unitConverters
      .precipitation(row.precipitation_sum_mm, { decimalPlaces: 1 })
      .valueWithSuffix()

    return {
      key: row.timestamp,
      dayLabel,
      icon,
      temperature_min,
      temperature_max,
      windSpeed,
      windDirection,
      precipitation,
    }
  })

  return (
    <table
      ref={wrapperDiv.ref}
      css={{
        'fontSize': isSmall ? 12 : 14,
        'tr:first-of-type td': { paddingTop: 0 },
        'td': { paddingTop: 6 },
      }}
    >
      <tbody>
        {shapedRows.map((row) => {
          return (
            <tr key={row.key}>
              <td css={{ color: colors.grey500 }}>{row.dayLabel}</td>
              <td>{weatherConditionToIcon(row.icon as EnumWeatherCondition)}</td>
              <td>
                {translate.phrases.placeholder('{{label}}: {{value}}', {
                  label: translate.phrases.abbreviations('High'),
                  value: row.temperature_max,
                })}
              </td>
              <td>
                {translate.phrases.placeholder('{{label}}: {{value}}', {
                  label: translate.phrases.abbreviations('Low'),
                  value: row.temperature_min,
                })}
              </td>
              <td>
                {row.windDirection !== null && <IconWindDirection windDirection={row.windDirection} />}
                {row.windDirection !== null && <>&nbsp;</>}
                {row.windSpeed}
              </td>
              {row.precipitation !== null && (
                <td>
                  {weatherConditionToIcon('wi-rain' as EnumWeatherCondition)}
                  &nbsp;
                  {row.precipitation}
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
