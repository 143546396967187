import type { routes } from '@semios/app-platform-banyan-route-definitions'
import { settings } from 'settings/settings'
import { errorCodes } from './errorCodes'

const DEFAULT_TIMEOUT = 30000 // 30 seconds

// prettier-ignore
export type TRouteMap = {
  [routes.LandingPageAllProducts.path]: {Request: routes.LandingPageAllProducts.Request; Response: routes.LandingPageAllProducts.Response},
  [routes.LandingPageFieldAssetConfigurations.path]: {Request: routes.LandingPageFieldAssetConfigurations.Request; Response: routes.LandingPageFieldAssetConfigurations.Response},
  [routes.LandingPageProductDetails.path]: {Request: routes.LandingPageProductDetails.Request; Response: routes.LandingPageProductDetails.Response},
  [routes.LandingPageSelfRegistrationRequest.path]: {Request: routes.LandingPageSelfRegistrationRequest.Request; Response: routes.LandingPageSelfRegistrationRequest.Response},
  [routes.LandingPageStartup.path]: {Request: routes.LandingPageStartup.Request; Response: routes.LandingPageStartup.Response},
  [routes.LandingPageValuesCurrent.path]: { Request: routes.LandingPageValuesCurrent.Request; Response: routes.LandingPageValuesCurrent.Response },
}

/**
 *
 * @param {string} url - the url of the post request on our API
 * @param {object} body - payload body for the request
 * @param {object} params - optional additional arguments for the request (method, headers, etc.)
 * @returns {Promise<object>} a promise of the API response
 */
export const apiFetch = async <T extends keyof TRouteMap>({
  url,
  body,
  params,
}: {
  url: T
  body: TRouteMap[T]['Request']
  params?: Omit<RequestInit, 'body'> & { timeout?: number }
}): Promise<TRouteMap[T]['Response']> => {
  let serializedBody: string | object | undefined | null = null

  if (body) {
    if (typeof body === 'string' || body instanceof FormData) {
      serializedBody = body
    } else {
      serializedBody = JSON.stringify(body)
    }
  }

  const { timeout = DEFAULT_TIMEOUT } = params || {}
  const controller = new AbortController()
  const timeoutRef = setTimeout(() => controller.abort(), timeout)
  const { headers, method, ...rest } = params || {}

  const requestOptions = {
    headers: {
      'Content-Type': body === null ? 'null' : 'application/json',
      'app-name': 'landing-page-app', //TODO,
      'Accept-Encoding': 'gzip',
      'referer-url': window.location.href,
      ...params?.headers,
    },
    method: params?.method || 'POST',
    body: serializedBody,
    ...rest,
    signal: controller.signal,
  } as RequestInit

  const urlToUse = `${settings.hubApiUrl}${`/${url}`.replace(/\/\/+/g, '/')}` // add a regex to delete extra slashes

  //The promise returned from fetch() won't reject on HTTP errors even if the response is an HTTP 404 or 500. Instead,
  //as soon as the server responds with headers, the promise will resolve (with the ok property of the response set to
  //false if the response isn't in the range 200–299). The promise will only reject on network failure or if anything
  //prevented the request from completing.
  return fetch(urlToUse, requestOptions)
    .then(errorCodes)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error) // TODO - landing page error handling
    })
    .finally(() => clearTimeout(timeoutRef))
}
